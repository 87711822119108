import React from 'react'
import styled from 'styled-components'

import { color } from '../styled/Global'

const NotAllowedWrap = styled.div`
    height: 300px;
    text-align: center;

    h1 {
        font-size: 260px;
        color: ${color.white};
        font-weight: 600;
        margin-bottom: 20px;
    }
`

const NotAllowed = () => {
    return (
        <NotAllowedWrap>
            <h1>403</h1>
        </NotAllowedWrap>
    )
}

export default NotAllowed
