import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { MyServicesProvider, MyServicesContext } from '../../contexts/Company.MyServicesContext'
import { Modal } from '../../styled/Modal'
import { Paginator, PageInfo } from '../../styled/Pagination'
import { Field, Legend } from '../../styled/Forms'
import { Button } from '../../styled/Cta'
import { IconPlus } from '../../styled/Icons'
import { Title } from '../../components/PageTitle'
import MyServiceCard from '../../components/cards/MyServiceCard'
import {AuthContext} from "../../contexts/AuthContext"
import {parseUrl} from "../../scripts/util";
import api from "../../api";
import axios from "axios";

const FormGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin: 0 auto 30px auto;
    max-width: 900px;
`

const FormActions = styled.div`
    text-align: center;

    button {
        margin: 0 10px;
    }
`

const Radios = styled.div`
    column-count: 2;
    column-gap: 20px;
`

const TextAreaFixed = styled.textarea`
    resize: none;
`

const AddService = () => {
    const auth = useContext(AuthContext)
    const { user } = auth
    const myServicesContext = useContext(MyServicesContext)
    const { categories, add, addModalState, setAddModalState } = myServicesContext

    const [name, setName] = useState(user.name)
    const [desc, setDesc] = useState()
    const [address, setAddress] = useState()
    const [cat, setCat] = useState()

    function formatString(str) {
        return str
            .replace(/(\B)[^ ]*/g, match => (match.toLowerCase()))
            .replace(/^[^ ]/g, match => (match.toUpperCase()));
    }

    function getAddressString(res_data) {
        let address_str = formatString(res_data.street + ' ' + res_data.street_number + ', ' +
            res_data.postcode + ' ' + res_data.city)
        address_str += ' (' + (res_data.province).toUpperCase() + ')'
        return address_str
    }

    useEffect(() => {
        const url = parseUrl(api.express.company.company_get);
        axios.post(url, {id: user.id}, { withCredentials: true })
            .then(res => {
                setAddress(getAddressString(res.data))
            })
            .catch(() => {
                setAddress('')
            })
    }, [])

    const handleSubmit = e => {
        e.preventDefault()
        if (!name || !desc || !cat || !address) { return }
        add({ name, desc, cat, address })
    }

    return (
        <>
            <Button icon={IconPlus} mb="30px" onClick={() => setAddModalState(!addModalState)}>Nuovo</Button>
            <Modal
                title="Nuovo servizio"
                isOpen={addModalState}
                close={() => setAddModalState(!addModalState)}
            >
                <form onSubmit={e => handleSubmit(e)}>
                    <FormGrid>
                        <Field>
                            <label>Nome</label>
                            <input required type="text" value={name} onChange={e => setName(e.target.value)} />
                        </Field>
                        <Field>
                            <label>Descrizione</label>
                            <TextAreaFixed rows="6" required type="text" onChange={e => setDesc(e.target.value)}/>
                        </Field>
                        <Field>
                            <label>Indirizzo</label>
                            <input required type="text" value={address} onChange={e => setAddress(e.target.value)} />
                        </Field>
                        <Legend>Categorie</Legend>
                        <Radios>
                            {categories.filter(cat => cat.special === undefined || cat.special === false).map(cat =>
                                <Field radio key={cat.category_id}>
                                    <input name="category" id={cat.category_id} type="radio" value={cat.category_id} onChange={e => setCat(e.target.value)} />
                                    <div className="fake-input"></div>
                                    <label htmlFor={cat.category_id}>{cat.name}</label>
                                </Field>
                            )}
                        </Radios>
                    </FormGrid>
                    <FormActions>
                        <Button icon={IconPlus}>Aggiungi</Button>
                    </FormActions>
                </form>
            </Modal>

        </>
    )
}

const Table = styled.div`
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
`

const Services = ({ services, categories }) => {
    return (
        <Table>
            {services.map((el) =>
                <MyServiceCard
                    key={el.service_id}
                    id={el.service_id}
                    name={el.name}
                    desc={el.description}
                    address={el.address}
                    cat={categories.filter(cat => cat.category_id === el.category_id)}
                />
            )}
        </Table>
    )
}

const MyServicesContent = () => {
    const myServicesContext = useContext(MyServicesContext)
    const { services, categories, pagin, loading, setCurrentPage } = myServicesContext

    return (
        <>
            {!loading
                ? <>
                    <Title title="I tuoi servizi" />
                    <AddService />
                    <PageInfo tot={pagin.total} curr={pagin.current_page} last={pagin.last_page} />
                    <Services services={services} categories={categories} />
                    <Paginator pageCount={pagin.last_page} onPageChange={page => setCurrentPage(page + 1)} />
                </>
                : ''
            }
        </>
    )
}

const MyServices = () => {
    return (
        <MyServicesProvider>
            <MyServicesContent />
        </MyServicesProvider>
    )
}

export default MyServices;
