import React, { useState, useEffect, createContext } from 'react'
import axios from 'axios'
import api from '../api.json'
import { parseUrl, appendToUrl } from '../scripts/util'

export const MyServicesContext = createContext()

export const MyServicesProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [services, setServices] = useState()
    const [categories, setCategories] = useState();
    const [pagin, setPagin] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [addModalState, setAddModalState] = useState(false)

    useEffect(() => {
        // services list and categories
        const baseCatUrl = parseUrl(api.express.employee.service_categories)
        const catUrl = appendToUrl(baseCatUrl, {level: 0})

        axios.all([
            axios.get(parseUrl(api.express.company.my_services, [currentPage]), { withCredentials: true }),
            axios.get(catUrl, { withCredentials: true }),
        ])
            .then(resArray => {
                setServices(resArray[0].data.data);
                setPagin(resArray[0].data.meta);
                setCategories(resArray[1].data.data)
                setLoading(false);
            })
    }, []);

    useEffect(() => {
        updateServices()
    }, [currentPage]);

    const updateServices = () => {
        // only services list
        axios.get(parseUrl(api.express.company.my_services, [currentPage]), { withCredentials: true })
            .then(res => {
                setServices(res.data.data);
                setPagin(res.data.meta);
            })
    }

    const add = data => {
        // add service and refresh list
        const url = parseUrl(api.express.company.service_add)
        axios.post(url, { data }, { withCredentials: true })
            .then(() => {
                updateServices()
                setAddModalState(!addModalState)
            })
            .catch(err => {
                console.log(err.response)
            })
    }

    const edit = (id, data) => {
        const url = parseUrl(api.express.company.service_update)
        axios.post(url, { id, data }, { withCredentials: true })
            .then(() => {
                updateServices()
            })
            .catch(err => {
                console.log('error on edit', err.response)
            })
    }

    const remove = id => {
        const url = parseUrl(api.express.company.service_remove)
        axios.post(url, { id }, { withCredentials: true })
            .then(() => {
                updateServices()
            })
            .catch(err => {
                console.log('error on delete', err.response)
            })
    }

    return (
        <MyServicesContext.Provider value={{
            loading,
            services,
            categories,
            pagin,
            updateServices,
            add,
            addModalState,
            setAddModalState,
            edit,
            remove,
            setCurrentPage
        }}>
            {children}
        </MyServicesContext.Provider>
    )
}
