import React, { useState, useContext } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import api from '../../api.json'
import { parseUrl } from '../../scripts/util'
import { EmployeesProvider, EmployeesContext } from '../../contexts/Company.EmployeesContext'
import { color } from '../../styled/Global'
import { Paginator, PageInfo } from '../../styled/Pagination'
import EmployeeCard from '../../components/cards/EmployeeCard'
import EmployeeImport from "./EmployeeImport";
import EmployeeAddForm from './EmployeeAddForm'
import { Callout } from '../../styled/Callout'
import { Button } from '../../styled/Cta'
import { IconError, IconPlus } from '../../styled/Icons'
import { Title } from '../../components/PageTitle'
import { Modal } from '../../styled/Modal'

const StyledEmployees = styled.div`
    border-radius: 4px;
    background: ${color.white};
    padding: 20px;
    padding-bottom: 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
`;

const StyledNoResult = styled.div`
    display: flex;
    align-items: center;

    > div {
        margin: 0 auto;
        max-width: 400px;
        text-align: center;
    }

    .icon {
        margin-bottom: 15px;
    }
`

const NoResult = () => {
    return (
        <StyledNoResult>
            <div>
                <IconError className="icon" hex={color.error} size={30} />
                <span>Nessun risultato</span>
            </div>
        </StyledNoResult>
    )
};

const Employees = ({ employees }) => {
    const [inviteSuccessMsg, setInviteSuccessMsg] = useState(false)
    const [inviteErrorMsg, setInviteErrorMsg] = useState(false)
    
    const invite = id => {
        resetMsgs();
        const url = parseUrl(api.express.company.employee_invite)
        axios.post(url, { employeeId: id }, { withCredentials: true })
            .then(res => {
                setInviteSuccessMsg('Success!')
            })
            .catch(err => {
                setInviteErrorMsg(err.response.data)
            })
    }

    const resetMsgs = () => {
        setInviteSuccessMsg()
        setInviteErrorMsg()
    }

    return employees.length ? (
        <>
            {inviteSuccessMsg
                ? <Callout status="success" title={inviteSuccessMsg} />
                : ''
            }
            {inviteErrorMsg
                ? <Callout status="error" title={inviteErrorMsg} />
                : ''
            }
            <StyledEmployees>
                {employees.map(el =>
                    <EmployeeCard key={el.employee_id}
                        id={el.employee_id}
                        name={`${el.firstname} ${el.lastname}`}
                        firstname={el.firstname}
                        lastname={el.lastname}
                        taxcode={el.tax_code}
                        email={el.email}
                        address={el.address}
                        registered={el.registered}
                        is_admin={el.is_admin}
                        invited={el.invited}
                        quotes={el.quotes}
                        buttonText="Invita"
                        buttonIcon={IconPlus}
                        buttonAction={() => invite(el.employee_id)}
                    />
                )}
            </StyledEmployees>
        </>
    ) : (
        <NoResult />
    )
}

const EmployeeListContent = () => {
    const [isOpenImport, setIsOpenImport] = useState(false);
    const employeesContext = useContext(EmployeesContext);
    const { employees, pagin, loading, setCurrentPage } = employeesContext;
    const [addModalState, setAddModalState] = useState(false);

    return (
        <>
            {!loading
                ? <>
                    <Title title="I tuoi dipendenti" />
                    <Button icon={IconPlus} mb="30px" onClick={() => setAddModalState(!addModalState)}>Nuovo</Button>
                    <Modal 
                        title="Nuovo dipendente"
                        isOpen={addModalState}
                        close={() => setAddModalState(!addModalState)}
                    >
                        <EmployeeAddForm />
                    </Modal>
                    &nbsp;
                    <Button icon={IconPlus} mb="30px" onClick={() => setIsOpenImport(!isOpenImport)}>Importa</Button>
                    <Modal
                        title="Importa dipendenti"
                        isOpen={isOpenImport}
                        close={() => setIsOpenImport(!isOpenImport)}
                    >
                        <EmployeeImport />
                    </Modal>
                    <PageInfo tot={pagin.total} curr={pagin.current_page} last={pagin.last_page} />
                    <Employees employees={employees} />
                    <Paginator pageCount={pagin.last_page} onPageChange={page => setCurrentPage(page + 1)} />
                </>
                : ''
            }
        </>
    )
};

const EmployeeList = () => {
    return (
        <EmployeesProvider>
            <EmployeeListContent />
        </EmployeesProvider>
    )
}

export default EmployeeList
