import React, { useState, useEffect, createContext } from 'react'
import axios from 'axios'
import api from '../api.json'

import { parseUrl } from '../scripts/util'

export const EmployeeContext = createContext()

export const EmployeeProvider = ({ children }) => {
    const [employee, setEmployee] = useState({ isReady: false })

    const updateQuotes = () => {
        axios.get(parseUrl(api.express.employee.quote_list), { withCredentials: true })
            .then(res => {
                setEmployee(e => {
                    return {
                        ...e,
                        quotes: res.data.data
                    }
            })
        })
    }

    const updateContext = () => {
        axios.all([
            axios.get(parseUrl(api.express.employee.quote_list), { withCredentials: true }),
            axios.get(parseUrl(api.express.shared.user_info), { withCredentials: true }),
            axios.get(parseUrl(api.express.employee.request_list), { withCredentials: true })
        ])
            .then(resArray => {
                // console.log('quotes', resArray[0].data.data)
                // console.log('infos', resArray[1].data)
                // console.log('requests', resArray[2].data)
                setEmployee(e => {
                    return {
                        ...e,
                        quotes: resArray[0].data.data,
                        info: resArray[1].data,
                        requests: resArray[2].data,
                        isReady: true
                    }
                })
            })
    }

    useEffect(() => {
        updateContext()
    }, [])

    return (
        <EmployeeContext.Provider value={{
            employee,
            updateQuotes,
        }}>
            {children}
        </EmployeeContext.Provider>
    )
}
