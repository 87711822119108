import React from 'react'
import styled from 'styled-components'

import { color, shadow } from './Global'
import { IconSuccess, IconError } from './Icons'

const StyledCallout = styled.div`
    margin-bottom: 20px;
    padding: 12px 15px;
    letter-spacing: 1px;
    border-radius: 6px;
    box-shadow: ${shadow.strong};

    ${props => props.status === 'success' ? `
        background: ${color.white};
        color: ${color.success};
        border: 2px solid ${color.success};
    ` : ``}

    ${props => props.status === 'warning' ? `
        background: ${color.white};
        color: ${color.warning};
        border: 2px solid ${color.warning};
    ` : ``}

    ${props => props.status === 'error' ? `
        background: ${color.white};
        color: ${color.error};
        border: 2px solid ${color.error};
    ` : ``}
`

const CalloutHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon {
        flex: 0 0 auto;
    }

    .title {
        font-size: 16px;
        margin-left: 10px;
        font-weight: 600;
        flex: 1 1 0;
    }

    .close {
        flex: 0 0 auto;
    }
`

const CalloutBody = styled.div`
    padding-top: 10px;
    color: ${color.black};
    font-size: 14px;
    line-height: 1.4;
    white-space: pre-wrap;
`

export const Callout = ({ status, title, children }) => {
    return (
        <StyledCallout status={status}>
            <CalloutHeader>
                {status === 'success'
                    ? <IconSuccess className="icon" hex={color.success} size={25} />
                    : ''
                }
                {status === 'error'
                    ? <IconError className="icon" hex={color.error} size={25} />
                    : ''
                }
                <h3 className="title">{title}</h3>
            </CalloutHeader>
            {children
                ? <CalloutBody>
                    {children}
                </CalloutBody>
                : ''
            }
        </StyledCallout>
    )
}
