import React from 'react';
import styled from 'styled-components';
import ReactPaginate from 'react-paginate';

import { color } from './Global';

const PaginatorWrapper = styled.div`
    padding-top: 20px;
    margin-bottom: 50px;
    text-align: center;

    .pagination {
        display: inline-flex;
    }

    li {
        margin: 0 4px;
        min-width: 30px;

        a {
            background: ${color.white};
            display: inline-block;
            padding: 10px;
            cursor: pointer;
            border-radius: 22px;
            width: 40px;
            height: 40px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            color: ${color.darkGray};
        }

        &.active {
            a {
                background: ${color.warning};
                font-weight: 600;
            }
        }

        &.previous,
        &.next {
            a {
                background: none;
                position: relative;
                height: 100%;
                width: 100%;

                &:before {
                    position: absolute;
                    top: 50%;
                    left: 55%;
                    transform: translate(-50%, -50%) rotate(135deg);
                    content: '';
                    border: solid black;
                    border-width: 0 2px 2px 0;
                    display: inline-block;
                    padding: 3px;
                }
            }
        }

        &.next {
            a {
                &:before {
                    left: 45%;
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
            }
        }

        &.disabled {
            a {
                cursor: default;
                pointer-events: none;
                
                &:before {
                    opacity: .4;
                }
            }
        }
    }
`

export function Paginator(props) {
    const { pageCount, onPageChange } = props;
    if (pageCount > 1) {
        return (
            <PaginatorWrapper>
                <ReactPaginate
                    previousLabel={''}
                    nextLabel={''}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pageCount}
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={5}
                    onPageChange={i => onPageChange(i.selected)}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                />
            </PaginatorWrapper>
        )
    } else { return <></>}
}

const StyledPageInfo = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    margin-bottom: 30px;
    border-top: 1px solid ${color.gray};
    border-bottom: 1px solid ${color.gray};
    font-size: 13px;
    color: ${color.darkGray};
`

export function PageInfo(props) {
    return (
        <StyledPageInfo>
            <div><strong>{props.tot}</strong> risultati</div>
            <div>Pagina <strong>{props.curr}</strong> di {props.last}</div>
        </StyledPageInfo>
    )
}