import React from 'react'
import styled from 'styled-components'
import BaseModal from 'styled-react-modal'
import { color, mq } from './Global'
import { Button } from './Cta'
import { Field } from "./Forms"
import { IconCheck, IconCross } from './Icons'
import { Callout as BaseCallout } from "./Callout";

const StyledModal = BaseModal.styled`
    width: 90vw;
    max-width: ${props => props.mx ? props.mx : `700px`};
    max-height: 90vh;
    padding: 30px;
    background-color: ${color.white};
    position: relative;
    overflow: auto;

    @media ${mq.mediumDown} {
        width: 100%;
        max-width: 100%;
        height: calc(100vh - 50px);
        max-height: 100vh;
        padding: 40px;
        align-self: start;
        margin-top: 50px;
    }

    @media ${mq.smallOnly} {
        padding: 20px;
        padding-top: 40px;
    }

    .close-modal {
        position: fixed;
        top: 70px;
        right: 0;
        cursor: pointer;
        padding: 10px;
        
        @media ${mq.large} {
            display: none;
        }
    }
`

const ModalTitle = styled.h1`
    text-align: center;
    font-size: 25px;
    margin-bottom: 20px;
    color: ${color.blue};
    text-transform: uppercase;
    letter-spacing: 1px;
`

const ConfirmMsg = styled.p`
    margin-bottom: 30px;
    text-align: center;
`

const ConfirmActions = styled.div`
    display: flex;
    justify-content: center;
`

const Callout = styled(BaseCallout)`
    margin-bottom: 30px;
`

const FieldReason = styled(Field)`
    margin-bottom: 30px;
`

export const Modal = ({ title, children, close, ...rest }) => {
    return (
        <StyledModal onBackgroundClick={close} onEscapeKeydown={close} {...rest}>
            <IconCross className="close-modal" onClick={close} />
            {title
                ? <ModalTitle>{title}</ModalTitle>
                : ''
            }
            {children}
        </StyledModal>
    )
}

export const ConfirmModal = ({ title, msg, onConfirm, onCancel, close, successMessage, errorMessage, singleButton, ...rest }) => {
    return (
        <StyledModal {...rest}>
            <IconCross className="close-modal" onClick={close} />
            {successMessage
                ? <Callout status="success" title={successMessage} />
                : ''
            }
            {errorMessage
                ? <Callout status="error" title={errorMessage} />
                : ''
            }
            {title
                ? <ModalTitle>{title}</ModalTitle>
                : ''
            }
            {msg
                ? <ConfirmMsg>{msg}</ConfirmMsg>
                : ''
            }
            {singleButton
                ? <ConfirmActions>
                    <Button ml="5px" mr="5px" icon={IconCheck} onClick={onConfirm}>Ok</Button>
                </ConfirmActions>
                : <ConfirmActions>
                    <Button ml="5px" mr="5px" icon={IconCross} onClick={onCancel}>No</Button>
                    <Button ml="5px" mr="5px" icon={IconCheck} onClick={onConfirm}>Si</Button>
                </ConfirmActions>
            }
        </StyledModal>
    )
}

export const ConfirmModalReason = ({ title, msg, reason, setReason, onConfirm, onCancel, close, successMessage, errorMessage, singleButton, ...rest }) => {
    return (
        <StyledModal {...rest}>
            <IconCross className="close-modal" onClick={close} />
            {successMessage
                ? <Callout status="success" title={successMessage} />
                : ''
            }
            {errorMessage
                ? <Callout status="error" title={errorMessage} />
                : ''
            }
            {title
                ? <ModalTitle>{title}</ModalTitle>
                : ''
            }
            {msg
                ? <ConfirmMsg>{msg}</ConfirmMsg>
                : ''
            }
            <FieldReason>
                <label>Motivazione</label>
                <input required type="text" value={reason} onChange={e => setReason(e.target.value)} />
            </FieldReason>            
            {singleButton
                ? <ConfirmActions>
                    <Button ml="5px" mr="5px" icon={IconCheck} onClick={onConfirm}>Ok</Button>
                </ConfirmActions>
                : <ConfirmActions>
                    <Button ml="5px" mr="5px" icon={IconCross} onClick={onCancel}>No</Button>
                    <Button ml="5px" mr="5px" icon={IconCheck} onClick={onConfirm}>Si</Button>
                </ConfirmActions>
            }
        </StyledModal>
    )
}
