import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import axios from "axios"

import { EmployeesContext } from '../../contexts/Company.EmployeesContext'
import api from '../../api.json';
import { parseUrl } from '../../scripts/util'
import { Button } from '../../styled/Cta'
import { Field } from '../../styled/Forms'
import { mq } from '../../styled/Global'
import { Callout as BaseCallout } from '../../styled/Callout'

const Callout = styled(BaseCallout)`
    margin-bottom: 30px;
`

const FieldCheckBox = styled(Field)`
    margin-bottom: 35px;
    margin-top: 34px;
`

const FormGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${props => props.gap ? props.gap : `20px`};
    margin: 0 auto 30px auto;
    max-width: 900px;

    @media ${mq.smallOnly} {
        grid-template-columns: 1fr;
    }
`

const WelfareParagraph = styled.p`
    font-size: 10px;
    margin-bottom: 7px;
    padding-left: 6px;
`

const WelfareQuotesManager = styled.div`
    h3 {
        text-align: center;
        color: #2a5298;
        text-transform: uppercase;
    }
    
    ul {
        margin: 1rem 0 1rem 0;
        font-size: 12px;
    }
    
    li {
        padding: 1rem;
        border-bottom: 1px solid #BCBCBC;
    }
    
    #year {
        border-radius: 25px;
        padding: 4px 8px;
        font-weight: 600;
        color: #FFFFFF;
        background: #939394;
    }
    
    #separator {
        border-right: 1px solid #BCBCBC;
        margin: 0 15px 0 15px;
    }
    
    #residual {
        border-radius: 25px;
        padding: 4px 8px;
        font-weight: 600;
        color: #FFFFFF;
        background: #2a5298;
        margin-right: 10px;
    }
`

const FormActions = styled.div`
    text-align: center;

    button {
        margin: 0 10px;
    }
`

const EmployeeEditForm = (props) => {
    const employeesContext = useContext(EmployeesContext)
    const { updateList } = employeesContext

    const [errorMsg, setEerrorMsg] = useState(false)
    const [successMsg, setSuccessMsg] = useState(false)

    const [city, setCity] = useState(props.city || '')
    const [street, setStreet] = useState(props.street || '')
    const [streetNumber, setStreetNumber] = useState(props.streetNumber || '')
    const [capNumber, setCAPNumber] = useState(props.postcode || '')
    const [province, setProvince] = useState(props.province || '')
    const [phone, setPhone] = useState(props.phone || '')
    // const [fax, setFax] = useState(props.fax || '')
    // const [website, setWebsite] = useState(props.website || '')
    const [firstname, setFirstName] = useState(props.firstname || '') // *
    const [lastname, setLastName] = useState(props.lastname || '') // *
    const [email, setEmail] = useState(props.email || '') // *
    const [taxCode, setTaxCode] = useState(props.taxCode || '') // *
    const [welfare, setWelfare] = useState(props.threshold || '') // *
    const [is_admin, setIsAdmin] = useState(props.is_admin)
    const [quotes, setQuotes] = useState(props.quotes);

    // insert a new employee
    const handleSubmit = e => {
        e.preventDefault()

        const data = {
            city: city || '',
            street: street || '',
            street_number: streetNumber || '',
            postcode: capNumber || '',
            province: province || '',
            phone: phone || '',
            // fax: fax || '',
            // website: website || '',
            firstname: firstname || '',
            lastname: lastname || '',
            email: email || '',
            tax_code: taxCode || '',
            welfare: welfare || '',
            is_admin: is_admin
        }

        const url = parseUrl(api.express.company.employee_update)
        axios.post(url, { id: props.id, data: data }, { withCredentials: true })
            .then(res => {
                setEerrorMsg(false)
                setSuccessMsg("Dipendente aggiornato con successo")
            })
            .catch(error => {
                setSuccessMsg(false)
                setEerrorMsg("Errore durante l'aggiornamento del dipendente")
            })
            .finally(() => {
                updateList()
            })
    }

    return (
        <form onSubmit={e => handleSubmit(e)}>
            {errorMsg
                ? <Callout status="error" title={errorMsg} />
                : ''
            }
            {successMsg
                ? <Callout status="success" title={successMsg} />
                : ''
            }
            <FormGrid>
                <Field>
                    <label>Nome *</label>
                    <input required type="text" value={firstname} onChange={e => setFirstName(e.target.value)} />
                </Field>
                <Field>
                    <label>Cognome *</label>
                    <input required type="text" value={lastname} onChange={e => setLastName(e.target.value)} />
                </Field>
                <Field>
                    <label>Email *</label>
                    <input required type="email" value={email} onChange={e => setEmail(e.target.value)} />
                </Field>
                <Field>
                    <label>C.F. *</label>
                    <input required type="text" value={taxCode} onChange={e => setTaxCode(e.target.value)} />
                </Field>
                <Field>
                    <label>Città</label>
                    <input type="text" value={city} onChange={e => setCity(e.target.value)} />
                </Field>
                <Field>
                    <label>Via</label>
                    <input type="text" value={street} onChange={e => setStreet(e.target.value)} />
                </Field>
                <Field>
                    <label>N. Civico</label>
                    <input type="text" value={streetNumber} onChange={e => setStreetNumber(e.target.value)} />
                </Field>
                <Field>
                    <label>CAP</label>
                    <input type="text" value={capNumber} onChange={e => setCAPNumber(e.target.value)} />
                </Field>
                <Field>
                    <label>Provincia</label>
                    <input type="text" value={province} onChange={e => setProvince(e.target.value)} />
                </Field>
                <Field>
                    <label>Telefono</label>
                    <input type="tel" value={phone} onChange={e => setPhone(e.target.value)} />
                </Field>
                <Field>
                    <label>Welfare (€) *</label>
                    <WelfareParagraph>(La quota welfare modificabile si riferisce all'anno corrente)</WelfareParagraph>
                    <input type="number" required value={welfare} onChange={e => setWelfare(e.target.value)} />
                </Field>
                <FieldCheckBox checkbox key="is_admin">
                    <input id="is_admin" name="is_admin" checked={is_admin} type="checkbox" value={is_admin} onChange={e => setIsAdmin(e.target.checked)} />
                    <div className="fake-input"/>
                    <label htmlFor="is_admin">Amministratore</label>
                </FieldCheckBox>
                <Field>
                    <label>(*) Campi obbligatori</label>
                </Field>
            </FormGrid>
            <WelfareQuotesManager>
                <h3>Gestione quote welfare</h3>
                <ul>
                {quotes.map((item) =>
                    <li key={item.welfare_quote_id}><span id="year">{item.year}</span> <span id="separator"/> <span id="residual">{item.threshold - item.value}€</span> di {item.threshold}€</li>)
                }
                </ul>
            </WelfareQuotesManager>
            <FormActions>
                <Button>Modifica</Button>
            </FormActions>
        </form>
    )
}

export default EmployeeEditForm
