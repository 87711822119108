// admin views
import OrganizationList from './admin/OrganizationList'
import ImportOrganization from './admin/ImportOrganization'

// company views
import EmployeeList from './company/EmployeeList'
import EmployeeRequest from './company/EmployeeRequest'
import CustomerRequest from './company/CustomerRequest'
import MyServices from './company/MyServices'

// organization
import CompanyList from './organization/CompanyList'
import ImportCompany from './organization/ImportCompany'

// employee
import Profile from './employee/Profile'
import Services from './employee/Services'
import ExpenseReportEmployee from "./employee/ExpenseReport";
import ExpenseReportOrganization from "./organization/ExpenseReport";
import Categories from './employee/Categories'

export const navs = {
    0: [],
    1: [{ // Admin
        name: 'Lista Organizzazioni',
        path: '/',
        component: OrganizationList
    }, {
        name: 'Importa organizzazione',
        path: '/import-organization',
        component: ImportOrganization
    }],
    2: [{ // Organization
        name: 'Aziende',
        path: '/',
        component: CompanyList
    }, {
        name: 'Importa azienda',
        path: '/import-company',
        component: ImportCompany
    }, {
        name: 'Servizi a rimborso',
        path: '/expense-report',
        component: ExpenseReportOrganization
    }],
    3: { // Company

        1: [{ // Supplier
            name: 'Dipendenti',
            path: '/',
            component: EmployeeList
        }, {
            name: 'Richieste',
            path: '/employee-requests',
            component: EmployeeRequest
        }],
        
        2: [{ // Provider
            name: 'Clienti',
            path: '/',
            component: CustomerRequest
        }, {
            name: 'Servizi',
            path: '/my-services',
            component: MyServices
        }],
        
        3: [{ // Supplier + Provider
            name: 'Dipendenti',
            path: '/',
            component: EmployeeList
        }, {
            name: 'Richieste',
            path: '/employee-requests',
            component: EmployeeRequest
        }, {
            name: 'Clienti',
            path: '/customer-requests',
            component: CustomerRequest
        }, {
            name: 'Servizi',
            path: '/my-services',
            component: MyServices
        }]
    },
    4: [{ // Employee
        name: 'Profilo',
        path: '/',
        component: Profile
    }, {
        name: 'Servizi per categoria',
        path: '/services/:categoryId',
        component: Services,
        hidden: true
    }, {
        name: 'Servizi',
        path: '/services',
        component: Categories
    },{
        name: 'Servizi a rimborso',
        path: '/expense-report',
        component: ExpenseReportEmployee
    }]
}
