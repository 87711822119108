import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import styled from 'styled-components'

import api from '../../api.json'
import { EmployeeContext } from '../../contexts/EmployeeContext'
import { Paginator, PageInfo } from '../../styled/Pagination'
import { Button } from '../../styled/Cta'
import { Field } from '../../styled/Forms'
import { parseUrl, appendToUrl } from '../../scripts/util'
import ServiceCard from '../../components/cards/ServiceCard'
import { Title } from '../../components/PageTitle'
import { IconSearch } from '../../styled/Icons'

const CenterWrapper = styled.div`
    max-width: 900px;
    margin: 0 auto;
`

const Search = styled.form`
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
`

const SearchInput = styled(Field)`
    flex: 1 1 0px;
    margin-right: 15px;
`
const SearchAction = styled.div`
    flex: 0 0 auto;
`

const Main = styled.div`
    grid-area: list;
`

const ServiceGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-bottom: 30px;
`

const Services = (props) => {
    const [loading, setLoading] = useState(true)
    const [services, setServices] = useState()
    const [category, setCategory] = useState()
    const [pagin, setPagin] = useState({})
    const [currentSearch, setCurrentSearch] = useState('')
    const [quotes, setQuotes] = useState()
    const [searchLoading, setSearchLoading] = useState(false)

    const employeeContext = useContext(EmployeeContext)
    const { employee } = employeeContext

    const search = e => {
        e.preventDefault()
        setSearchLoading(true)
        const baseUrlServices = parseUrl(api.express.employee.service_list)
        const urlServices = appendToUrl(baseUrlServices, { page: 1, category_id: props.match.params.categoryId,
            name: currentSearch, description: currentSearch, address: currentSearch })
        fetchDataOnlyServices(urlServices)

        setSearchLoading(false)
    }

    const fetchDataOnlyServices = (urlServices) => {
        axios.get(urlServices, { withCredentials: true })
            .then(res => {
                setServices(res.data.data)
                setPagin(res.data.meta)

                // show list
                setLoading(false)
            })
    }

    const fetchData = (urlServices, urlCategories) => {
        axios.all([
            axios.get(urlServices, { withCredentials: true }),
            axios.get(urlCategories, { withCredentials: true })
        ])
            .then(resArray => {
                setServices(resArray[0].data.data)
                setPagin(resArray[0].data.meta)
                setCategory(resArray[1].data.data.filter(el => el.category_id === parseInt(props.match.params.categoryId))[0].name)

                // show list
                setLoading(false)
            })
    }

    useEffect(() => {
        // get services data,
        const baseUrlServices = parseUrl(api.express.employee.service_list)
        const urlServices = appendToUrl(baseUrlServices, { page: 1, category_id: props.match.params.categoryId,
            name: currentSearch, description: currentSearch, address: currentSearch})

        const baseUrlCategories = parseUrl(api.express.employee.service_categories)
        const urlCategories = appendToUrl(baseUrlCategories, { page: 1, level: 0 })

        fetchData(urlServices, urlCategories)
    }, [props])

    useEffect(() => {
        if (!employee.quotes) { return }
        const arr = [];
        employee.quotes.forEach(el => {
            if (el.threshold - el.value > 0) {
                arr.push({
                    available: el.threshold - el.value,
                    value: el.welfare_quote_id,
                    label: `${el.supplier.name} (${el.year})`
                })
            }
        });
        setQuotes(arr);
    }, [employee.quotes])

    const updatePage = page => {
        // build url and update service list
        const baseUrl = parseUrl(api.express.employee.service_list, [])
        const url = appendToUrl(baseUrl, { page: page + 1, category_id: props.match.params.categoryId,
            name: currentSearch, description: currentSearch, address: currentSearch })
        fetchDataOnlyServices(url)
    }

    return (
        <>
            {!loading
                ? <CenterWrapper>
                    <Title title={category} />
                    <Search onSubmit={e => search(e, props)}>
                        <SearchInput mb="0" mr="15px">
                            <input type="text" placeholder="Cerca per nome, descrizione o indirizzo" value={currentSearch} onChange={e => setCurrentSearch(e.target.value)} />
                        </SearchInput>
                        <SearchAction>
                            <Button loading={searchLoading} icon={IconSearch}>Cerca</Button>
                        </SearchAction>
                    </Search>
                    <Main>
                        <PageInfo tot={pagin.total} curr={pagin.current_page} last={pagin.last_page} />
                        <ServiceGrid>
                            {services.map((el) =>
                                <ServiceCard key={el.service_id}
                                    id={el.service_id}
                                    name={el.name}
                                    desc={el.description}
                                    address={el.address}
                                    quotes={quotes} />
                            )}
                        </ServiceGrid>
                        <Paginator pageCount={pagin.last_page} onPageChange={updatePage} />
                    </Main>
                </CenterWrapper>
                : ''
            }
        </>
    )
}

export default Services
