import React, { useState, useEffect } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { mq } from '../../styled/Global'
import api from '../../api.json'
import { parseUrl, appendToUrl } from '../../scripts/util'
import CategoryCard from '../../components/cards/CategoryCard'

const CategoryGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    margin-bottom: 50px;
    
    @media ${mq.smallOnly} {
        grid-template-columns: 1fr;
    }
`

const Categories = () => {
    const [loading, setLoading] = useState(true)
    const [categories, setCategories] = useState([])
    const [iconById, setIconById] = useState({})

    const fetchData = url => {
        // get categories data, the update states
        axios.get(url, { withCredentials: true })
            .then(res => {
                setCategories(res.data.data)
            })
    }

    const fetchIcons = () => {
        const url = api.express.employee.service_categories_icon
        let iconsByIds = {}
        axios.all(
            categories.map((el) =>
                axios.post(parseUrl(url), {id: el.category_id}, { withCredentials: true })
                    .then(res => {
                        iconsByIds[el.category_id] = res.data
                    })
                    .catch(() => {
                        iconsByIds[el.category_id] = undefined
                    })
            )
        )
        .then(() => {
            setIconById(iconsByIds)
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchIcons()
    }, [categories])

    useEffect(() => {
        const baseUrl = parseUrl(api.express.employee.service_categories, [])
        const url = appendToUrl(baseUrl, { page: 1, level: 0 })
        fetchData(url)
    }, [])

    return (
        <>
            {!loading
                ? <CategoryGrid>
                    {categories.map((el =>
                        <CategoryCard key={el.category_id}
                            id={el.category_id}
                            name={el.name}
                            desc={el.description}
                            icon={iconById[el.category_id]}
                            special={el.special}
                        />
                    ))}
                </CategoryGrid>
                : ''
            }
        </>
    )
}

export default Categories
