import styled from "styled-components";
import { color } from "./Global";

const spin = () => `
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`

export const Spinner = styled.div`
    font-size: 10px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: ${color.darkBlue};
    background: linear-gradient(to right, ${color.darkBlue} 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    animation: spin 1s infinite linear;
    transform: translateZ(0);

    &:before {
        width: 50%;
        height: 50%;
        background: ${color.blue};
        border-radius: 100% 0 0 0;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
    }
  
    &:after {
        background: ${color.lightGray};
        width: 75%;
        height: 75%;
        border-radius: 50%;
        content: '';
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    ${spin}
`

export const PageSpinner = styled(Spinner)`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

export const ButtonSpinner = styled.div`
    width: 16px;
    height: 16px;
    background: ${color.darkBlue};
    background: linear-gradient(to right, ${color.darkBlue} 10%, rgba(255, 255, 255, 0) 42%);
    position: absolute;
    animation: spin 1s infinite linear;
    top: 50%;
    left: 50%;
    margin-top: -8px;
    margin-left: -8px;

    &:before {
        width: 50%;
        height: 50%;
        background: ${color.white};
        border-radius: 100% 0 0 0;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
    }
  
    &:after {
        background: ${color.blue};
        width: 75%;
        height: 75%;
        border-radius: 50%;
        content: '';
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    ${spin}
`