import React from 'react';
import styled from 'styled-components';

import { color, mq } from '../../styled/Global';

const StyledCard = styled.div`
    padding: 0;
    background: ${color.white};
    display: grid;
    grid-template-columns: 1fr 1fr 120px 200px;
    align-items: center;
    padding: 20px 15px;
    line-height: 1.4;
    
    @media ${mq.mediumOnly} {
        grid-template-columns: 1fr 1fr 120px 130px;
    }

    @media ${mq.smallOnly} {
        grid-template-columns: 1fr;
    }

    &:nth-child(even) {
        background: #e5e7ea;
    }

    .service-name {
        font-weight: 600;
        color: ${color.blue}
    }

    .service-description,
    .service-id,
    .token {
        display: block;
        font-size: 13px;
        color: ${color.darkGray}
    }

    .submitter-name {
        display: block;
    }

    .request-date {
        display: block;
        font-size: 13px;
        color: ${color.darkGray};
        
        @media ${mq.smallOnly} {
            margin-top: 10px;
        }
    }

    .quote {
        justify-self: center;

        @media ${mq.mediumDown} {
            margin-top: 10px;
            justify-self: start;
        }
    }

    .status {
        justify-self: self-end;

        @media ${mq.mediumDown} {
            margin-top: 10px;
            justify-self: start;
        }
    }
`;

const StatusTicket = styled.span`
    position: relative;
    display: inline-block;
    padding: 3px 12px;
    font-size: 12px;
    letter-spacing: 1px;
    border-radius: 15px;
    color: ${color.white};

    &.error {
        background: ${color.error};
    }

    &.success {
        background: ${color.success};
    }

    &.pending {
        background: ${color.warning};
    }

    &.closed {
        background: ${color.error};
    }

    &.clean {
        color: ${color.black};
    }
`

const Quote = styled.div`
    font-size: 13px;
    display: inline-block;
    border-radius: 25px;
    padding: 4px 10px;
    font-weight: 600;
    color: ${color.white};
    background: ${color.blue};
`

const MyRequestCard = props => {
    const statusLabels = {
        closed: {
            label: 'confermato',
            class: 'success'
        },
        accepted: {
            label: 'in attesa',
            class: 'pending'
        },
        pending: {
            label: 'in attesa',
            class: 'pending'
        },
        cancelled: {
            label: 'cancellato',
            class: 'error'
        }
    }

    return (
        <StyledCard>
            <div className="title">
                <h1 className="service-name">{props.name}</h1>
                <span className="service-description">{props.description}</span>
                <span className="token">Codice: {props.token}</span>
                {/*<span className="service-id">ID: {props.id}</span>*/}
            </div>
            <div className="submitter">
                <span className="submitter-name">{props.submittedBy}</span>
                <span className="request-date">il {props.created}</span>
            </div>
            <div className="quote">
                <Quote>{props.price} €</Quote>
            </div>
            <div className="status">
                <StatusTicket className={statusLabels[props.status].class}>{statusLabels[props.status].label}</StatusTicket>
            </div>
        </StyledCard>
    );
}

export default MyRequestCard;
