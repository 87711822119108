import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { color, mq } from '../../styled/Global'
import { Button } from '../../styled/Cta'
import { ToggleContent } from '../ToggleContent'
import { IconDown, IconEdit } from '../../styled/Icons'
import { Modal } from '../../styled/Modal'
import CompanyEditForm from '../../view/organization/CompanyEditForm'

const Card = styled.div`
    border-bottom: 1px solid ${color.gray};
    position: relative;
    overflow: hidden;
    padding: 15px 0;
    display: grid;
    grid-template-areas: 
        "main actions"
        "info info";
    grid-template-columns: 1fr 100px;

    @media ${mq.smallOnly} {
        grid-template-areas: 
            "main"
            "info"
            "actions";
        grid-template-columns: 1fr;
    }

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        border-color: transparent;
    }

    .main {
        grid-area: main;
        display: flex;
        align-items: center;
    }

    .actions {
        grid-area: actions;
        justify-self: end;

        @media ${mq.smallOnly} {
            padding-top: 10px;
        }
    }

    .info {
        grid-area: info;
    }
`;

const CardTitle = styled.h1`
    font-size: 16px;
    font-weight: 600;
    letter-spacing: .5px;
    color: ${color.black};
    cursor: pointer;
`

const StatusSticker = styled.div`
    display: inline-block;
    position: relative;
    padding-right: 15px;
    font-size: 14px;
    
    &:after {
        content: '';
        width: 9px;
        height: 9px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

    }
    
    &.success {
        &:after {
            background: ${color.success}
        }
    }

    &.warning {
        &:after {
            background: ${color.warning}
        }
    }

`

const MoreInfo = styled.div`
    width: 100%;
    font-size: 13px;
    padding-top: 10px;
    padding-left: 35px;
    display: block;
    color: ${color.darkGray};
    line-height: 1.8;

    .value {
        color: ${color.black};
    }
`
const ToggleTrigger = styled.button`

    background: none;
    border: none;
    transition: all .4s ease;

    &.isActive {
        transform: rotate(180deg);
    }
`

const ButtonModify = styled(Button)`
    margin-top: 20px;
`

function CompanyCard(props) {
    const [isVisible, setIsVisible] = useState(props.showInfo || false)
    const [is_supplier, setIsSupplier] = useState(true)  // Company type supplier (1)
    const [is_provider, setIsProvider] = useState(true)  // Company type provider (2)
    const [companyType, setCompanyType] = useState('Erogatore e fornitore')
    const [companyData, setCompanyData] = useState(props)
    const [editModalState, setEditModalState] = useState()

    const populateCompanyData = () =>{
        switch (props.type) {
            case 'supplier': 
                setIsProvider(false); 
                setIsSupplier(true); 
                setCompanyType('Erogatore')
                break;
            case 'provider':
                setIsProvider(true);
                setIsSupplier(false);
                setCompanyType('Fornitore')
                break;
            case 'both': default:
                setIsProvider(true);
                setIsSupplier(true);
                setCompanyType('Erogatore e fornitore')
        }
    }

    useEffect(() => {
        if (is_supplier && is_provider){
            setCompanyData({
                ...props,
                type: 'both'  // Both
            })
        }
        else{
            if ( is_supplier ){
                setCompanyData({
                    ...props,
                    type: 'supplier'  // Only supplier
                })
            }
            else{
                setCompanyData({
                    ...props,
                    type: 'provider'  // Only provider
                })
            }
        }

    }, [is_supplier, is_provider])


    useEffect(() => {
        populateCompanyData()
    }, [])

    useEffect(() => {
        populateCompanyData()
    }, [props.type])


    return (
        <Card>
            <div className="main">
                <ToggleTrigger className={isVisible ? 'isActive' : ''} onClick={() => setIsVisible(!isVisible)}>
                    <IconDown />
                </ToggleTrigger>
                <CardTitle onClick={() => setIsVisible(!isVisible)}>{props.name}</CardTitle>
            </div>
            <div className="info">
                <ToggleContent isVisible={isVisible}>
                    <MoreInfo>
                        P.IVA: <span className="value">{props.piva}</span><br />
                        Website: <span className="value">{props.address.website}</span><br />
                        Email: <span className="value">{props.email}</span><br />
                        PEC: <span className="value">{props.pec}</span><br />
                        Telefono: <span className="value">{props.address.phone}</span><br />
                        Fax: <span className="value">{props.address.fax}</span><br />
                        Indirizzo: <span className="value">{props.address.city}, {props.address.province} | {props.address.street} - {props.address.street_number}</span><br />
                        {!props.import
                            ? <>
                                Tipologia: <span className="value">{companyType}</span>
                              </>
                            : ''
                        }
                    </MoreInfo>
                </ToggleContent>
            </div>
            <div className="actions">
                {!props.registered
                    ? <Button icon={props.buttonIcon} onClick={props.buttonAction}>{props.buttonText}</Button>
                    : <StatusSticker className="success">Registrato</StatusSticker>
                }
                {!props.import
                    ? <>
                        <ButtonModify icon={IconEdit} onClick={() => setEditModalState(!editModalState)}>Modifica</ButtonModify>
                        <Modal
                            title="Aggiorna informazioni"
                            close={() => {setEditModalState(!editModalState)}}
                            isOpen={editModalState}>
                            <CompanyEditForm
                                id={props.id}
                                type={props.type}
                            />
                        </Modal>
                        <ButtonModify onClick={props.buttonUpdateAction}>{props.buttonUpdateText}</ButtonModify>
                      </>
                    : ''
                }
            </div>
        </Card>
    );
}

export default CompanyCard;