import React, { useContext } from 'react'

import { AuthContext } from '../contexts/AuthContext'
import { PageSpinner } from '../styled/Spinner'
import AdminView from './admin/AdminView'
import CompanyView from './company/CompanyView'
import EmployeeView from './employee/EmployeeView'
import OrganizationView from './organization/OrganizationView'

const Routes = () => {
    const authContext = useContext(AuthContext)
    const user = authContext.user
    const views = [AdminView, OrganizationView, CompanyView, EmployeeView]
    const View = views[user.role - 1]
    return (
        <>
            {user.isReady || (user.multipleUser && user.isLoggedIn)
                ? <View />
                : <PageSpinner />
            }
        </>
    )
}

export default Routes
