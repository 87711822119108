import React, { useState } from 'react'
import styled from 'styled-components'
import { color } from '../../styled/Global'
import { Button } from '../../styled/Cta'
import { ToggleContent } from '../ToggleContent'
import { IconDown } from '../../styled/Icons'

const Card = styled.div`
    border-bottom: 1px solid ${color.gray};
    position: relative;
    overflow: hidden;
    padding: 15px 0;
    display: grid;
    grid-template-areas: 
        "main actions"
        "info info";
    grid-template-columns: 1fr 100px;

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        border-color: transparent;
    }

    .main {
        grid-area: main;
        display: flex;
        align-items: center;
    }

    .actions {
        grid-area: actions;
        justify-self: end;
    }

    .info {
        grid-area: info;
    }
`;

const CardTitle = styled.h1`
    font-size: 16px;
    font-weight: 600;
    letter-spacing: .5px;
    color: ${color.black};
    cursor: pointer;
`

const StatusSticker = styled.div`
    display: inline-block;
    position: relative;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    margin-left: 12px;

    &.error {
        background: ${color.error}
    }

    &.success {
        background: ${color.success}
    }

    &.warning {
        background: ${color.warning}
    }
`

const MoreInfo = styled.p`
    width: 100%;
    font-size: 13px;
    padding-top: 10px;
    padding-left: 35px;
    display: block;
    color: ${color.darkGray};
    line-height: 1.8;

    .value {
        color: ${color.black};
    }
`

const ToggleTrigger = styled.button`
    background: none;
    border: none;
    transition: all .4s ease;

    &.isActive {
        transform: rotate(180deg);
    }
`

const UpdateButton = styled(Button)`
    margin-top: 20px;
`


function OrganizationCard(props) {
    const [isVisible, setIsVisible] = useState(props.showInfo || false);

    return (
        <Card>
            <div className="main">
                <ToggleTrigger className={isVisible ? 'isActive' : ''} onClick={() => setIsVisible(!isVisible)}>
                    <IconDown />
                </ToggleTrigger>
                <CardTitle onClick={() => setIsVisible(!isVisible)}>{props.name}</CardTitle>
                {!props.registered
                    ? !props.invited
                        ? '' // ? <StatusSticker className="error" />
                        : <StatusSticker className="warning" />
                    : <StatusSticker className="success" />
                }
            </div>
            <div className="info">
                <ToggleContent isVisible={isVisible}>
                    <MoreInfo>
                        P.IVA: <span className="value">{props.piva}</span><br />
                        Email: <span className="value">{props.email}</span><br />
                    </MoreInfo>
                </ToggleContent>
            </div>
            <div className="actions">
                {!props.registered
                    ? <Button onClick={props.buttonAction}>{props.buttonText}</Button>
                    : ''
                }
                {!props.import
                    ? <UpdateButton onClick={props.buttonUpdateAction}>{props.buttonUpdateText}</UpdateButton>
                    : ''
                }
            </div>
        </Card>
    );
}

export default OrganizationCard;