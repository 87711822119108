import React, { useContext } from 'react'
import { Route } from 'react-router-dom'

import { AuthContext } from '../contexts/AuthContext'
import NotAllowed from '../view/NotAllowed'

const PrivateRoute = ({ component: Component, ...rest }) => {
    const authContext = useContext(AuthContext)
    return (
        <Route {...rest} render={props => authContext.isAuthenticated()
            ? (<Component {...props} />)
            : (<NotAllowed />)}
        />
    )
}

export default PrivateRoute
