import React from 'react'
import styled from 'styled-components'
import { ModalProvider } from 'styled-react-modal'
import { BrowserRouter as Router } from "react-router-dom"

import { ResetStyles, color, mq } from './styled/Global'
import { AuthProvider } from './contexts/AuthContext'
import Header from './components/Header'
import Routes from './view/Routes'

const MainWrap = styled.div`
    background: ${color.lightGray};
    display: flex;
    flex-flow: row wrap;
    min-height: calc(100vh - 70px);
    margin-top: 70px;

    @media ${mq.mediumDown} {
        min-height: calc(100vh - 50px);
        margin-top: 50px;
    }
`;

const PageWrapper = styled.div`
    flex: auto;
    flex-flow: column wrap;
    display: flex;
    justify-content: start;
    align-items: center;
    flex: 1 1 0px;
    padding: 30px 0 50px 0;

    @media ${mq.mediumDown} {
        padding: 30px 0;
    }
`

const PageWrapperInner = styled.div`
    width: 100%;
    max-width: 1280px;
    padding: 0 15px;
    margin: 0 auto;
`

const Providers = ({ children }) => {
    return (
        <>
            <ResetStyles />
            <AuthProvider>
                <ModalProvider>
                    <Router>
                        {children}
                    </Router>
                </ModalProvider>
            </AuthProvider>
        </>
    );
}

const Layout = ({ children }) => {
    return (
        <>
            <Header />
            <MainWrap>
                <PageWrapper>
                    <PageWrapperInner>
                        {children}
                    </PageWrapperInner>
                </PageWrapper>
            </MainWrap>
        </>
    )
}

const App = () => {
    return (
        <Providers>
            <Layout>
                <Routes />
            </Layout>
        </Providers>
    )
}

export default App;
