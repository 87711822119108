import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import axios from "axios"

import { EmployeesContext } from '../../contexts/Company.EmployeesContext'
import api from '../../api.json';
import { parseUrl } from '../../scripts/util'
import { Button } from '../../styled/Cta'
import { Field } from '../../styled/Forms'
import { mq } from '../../styled/Global'
import { Callout as BaseCallout } from '../../styled/Callout'

const Callout = styled(BaseCallout)`
    margin-bottom: 30px;
`

const FieldCheckBox = styled(Field)`
    margin-top: 24px;
`

const FormGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${props => props.gap ? props.gap : `20px`};
    margin: 0 auto 30px auto;
    max-width: 900px;

    @media ${mq.smallOnly} {
        grid-template-columns: 1fr;
    }
`

const FormActions = styled.div`
    text-align: center;

    button {
        margin: 0 10px;
    }
`

const EmployeeAddForm = () => {
    const employeesContext = useContext(EmployeesContext)
    const { updateList } = employeesContext

    const [errorMsg, setEerrorMsg] = useState(false)
    const [successMsg, setSuccessMsg] = useState(false)

    const [city, setCity] = useState('')
    const [street, setStreet] = useState('')
    const [streetNumber, setStreetNumber] = useState('')
    const [capNumber, setCAPNumber] = useState('')
    const [province, setProvince] = useState('')
    const [phone, setPhone] = useState('')
    // const [fax, setFax] = useState('')
    // const [website, setWebsite] = useState('')
    const [firstname, setFirstName] = useState('') // *
    const [lastname, setLastName] = useState('') // *
    const [email, setEmail] = useState('') // *
    const [taxCode, setTaxCode] = useState('') // *
    const [welfare, setWelfare] = useState() // *
    const [is_admin, setIsAdmin] = useState(false)

    // insert a new employee
    const handleSubmit = e => {
        e.preventDefault()

        const newEmployee = {
            city: city || '',
            street: street || '',
            street_number: streetNumber || '',
            postcode: capNumber || '',
            province: province || '',
            phone: phone || '',
            // fax: fax || '',
            // website: website || '',
            firstname: firstname || '',
            lastname: lastname || '',
            email: email || '',
            tax_code: taxCode || '',
            welfare: welfare || '',
            is_admin: is_admin
        }

        const url = parseUrl(api.express.company.employee_add)
        axios.post(url, { userInfo: newEmployee }, { withCredentials: true })
            .then(res => {
                setEerrorMsg(false)
                setSuccessMsg("Dipendente creato con successo")
            })
            .catch(error => {
                console.log(error)
                setEerrorMsg('Errore durante la creazione del dipendente')
                setSuccessMsg(false)
            })
            .finally(() => {
                updateList()
            })
    }

    const handleReset = e => {
        e.preventDefault()

        setCity('')
        setStreet('')
        setStreetNumber('')
        setCAPNumber('')
        setProvince('')
        setPhone('')
        // setFax('')
        // setWebsite('')
        setFirstName('')
        setLastName('')
        setEmail('')
        setTaxCode('')
        setWelfare('')
        setIsAdmin(false)

        setEerrorMsg(false)
        setSuccessMsg(false)
    }

    return (
        <form onSubmit={e => handleSubmit(e)} onReset={e => handleReset(e)}>
            {errorMsg
                ? <Callout status="error" title={errorMsg} />
                : ''
            }
            {successMsg
                ? <Callout status="success" title={successMsg} />
                : ''
            }
            <FormGrid>
                <Field>
                    <label>Nome *</label>
                    <input required type="text" value={firstname} onChange={e => setFirstName(e.target.value)} />
                </Field>
                <Field>
                    <label>Cognome *</label>
                    <input required type="text" value={lastname} onChange={e => setLastName(e.target.value)} />
                </Field>
                <Field>
                    <label>Email *</label>
                    <input required type="email" value={email} onChange={e => setEmail(e.target.value)} />
                </Field>
                <Field>
                    <label>C.F. *</label>
                    <input required type="text" value={taxCode} onChange={e => setTaxCode(e.target.value)} />
                </Field>
                <Field>
                    <label>Città</label>
                    <input type="text" value={city} onChange={e => setCity(e.target.value)} />
                </Field>
                <Field>
                    <label>Via</label>
                    <input type="text" value={street} onChange={e => setStreet(e.target.value)} />
                </Field>
                <Field>
                    <label>N. Civico</label>
                    <input type="text" value={streetNumber} onChange={e => setStreetNumber(e.target.value)} />
                </Field>
                <Field>
                    <label>CAP</label>
                    <input type="text" value={capNumber} onChange={e => setCAPNumber(e.target.value)} />
                </Field>
                <Field>
                    <label>Provincia</label>
                    <input type="text" value={province} onChange={e => setProvince(e.target.value)} />
                </Field>
                <Field>
                    <label>Telefono</label>
                    <input type="tel" value={phone} onChange={e => setPhone(e.target.value)} />
                </Field>
                {/* <Field>
                    <label>Fax</label>
                    <input type="text" value={fax} onChange={e => setFax(e.target.value)} />
                </Field>
                <Field>
                    <label>Website</label>
                    <input type="text" value={website} onChange={e => setWebsite(e.target.value)} />
                </Field> */}
                <Field>
                    <label>Welfare (€) *</label>
                    <input type="number" required value={welfare} onChange={e => setWelfare(e.target.value)} />
                </Field>
                <FieldCheckBox checkbox key="is_admin">
                    <input id="is_admin" name="is_admin" type="checkbox" value={is_admin} onChange={e => setIsAdmin(e.target.checked)} />
                    <div className="fake-input"/>
                    <label htmlFor="is_admin">Amministratore</label>
                </FieldCheckBox>
                <Field>
                    <label>(*) Campi obbligatori</label>
                </Field>
            </FormGrid>
            <FormActions>
                {errorMsg || successMsg
                    ? <Button type='reset'>Reset</Button>
                    : ''
                }
                <Button>Aggiungi</Button>
            </FormActions>
        </form>
    )
}

export default EmployeeAddForm
