import React from 'react'
import styled from 'styled-components'

import { color } from '../styled/Global'

const SectionTitle = styled.h1`
    text-align: center;
    font-size: 20px;
    color: ${color.blue};
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;

    .icon {
        margin-right: 10px;
    }
`

export const Title = ({ icon: Icon, title }) => {
    return (
        <SectionTitle>
            {Icon
                ? <Icon size={30} className="icon"/>
                : ''
            }
            <span>{title}</span>
        </SectionTitle>
    )
}