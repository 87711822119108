import React, { useState, useContext } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import api from '../../api.json'
import { color } from '../../styled/Global'
import { FormGrid, Field } from '../../styled/Forms'
import { Button } from '../../styled/Cta'
import { Paginator, PageInfo } from '../../styled/Pagination'
import { IconError, IconFilter, IconPlus } from '../../styled/Icons'
import { Callout } from '../../styled/Callout'
import { parseUrl } from '../../scripts/util'
import CompanyCard from '../../components/cards/CompanyCard'
import { Title } from '../../components/PageTitle'
import { CompaniesProvider, CompaniesContext } from '../../contexts/Organization.CompaniesContext'

const StyledCompanies = styled.div`
    border-radius: 4px;
    background: ${color.white};
    padding: 20px;
    padding-bottom: 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
`

const FormActions = styled.div`
    grid-column: 3/4;
    justify-self: end;
`

const StyledNoResult = styled.div`
    display: flex;
    align-items: center;

    > div {
        margin: 0 auto;
        max-width: 400px;
        text-align: center;
    }

    .icon {
        margin-bottom: 15px;
    }
`

const NoResult = () => {
    return (
        <StyledNoResult>
            <div>
                <IconError className="icon" hex={color.error} size={30} />
                <span>Nessun risultato</span>
            </div>
        </StyledNoResult>
    )
}

const Companies = ({ companies }) => {
    const companiesContext = useContext(CompaniesContext)
    const { updateList } = companiesContext
    const [inviteSuccessMsg, setInviteSuccessMsg] = useState(false)
    const [inviteErrorMsg, setInviteErrorMsg] = useState(false)
    const [updateSuccessMsg, setUpdateSuccessMsg] = useState(false)
    const [updateErrorMsg, setUpdateErrorMsg] = useState(false)

    const updateCompany = (piva) => {
        resetMsgs()
        const url = parseUrl(api.express.organization.company_update_crm)
        axios.post(url, { vat: piva }, { withCredentials: true })
            .then(res => {
                updateList()
                setUpdateSuccessMsg('Organizzazione aggiornata!')
            })
            .catch(err => {
                setUpdateErrorMsg(err.response.data)
            })
    }

    const invite = id => {
        resetMsgs();
        const url = parseUrl(api.express.organization.company_invite)
        axios.post(url, { companyId: id }, { withCredentials: true })
            .then(res => {
                setInviteSuccessMsg('Success!')
            })
            .catch(err => {
                setInviteErrorMsg(err.response.data)
            })
    }

    const resetMsgs = () => {
        setInviteSuccessMsg()
        setInviteErrorMsg()
    }

    return companies.length ? (
        <>
            {inviteSuccessMsg
                ? <Callout status="success" title={inviteSuccessMsg} />
                : ''
            }
            {inviteErrorMsg
                ? <Callout status="error" title={inviteErrorMsg} />
                : ''
            }
            {updateSuccessMsg
                    ? <Callout status="success" title={updateSuccessMsg} />
                    : ''
            }
            {updateErrorMsg
                ? <Callout status="error" title={updateErrorMsg} />
                : ''
            }
            <StyledCompanies>
                {companies.map(el =>
                    <CompanyCard key={el.company_id}
                        id={el.company_id}
                        name={el.name}
                        email={el.email}
                        piva={el.vat}
                        pec={el.pec}
                        address={el.address}
                        registered={el.registered}
                        invited={el.invited}
                        type={el.type}
                        buttonText="Invita"
                        buttonIcon={IconPlus}
                        buttonAction={() => invite(el.company_id)}
                        buttonUpdateText="Aggiorna"
                        buttonUpdateAction={() => updateCompany(el.vat)}
                    />
                )}
            </StyledCompanies>
        </>
    ) : (
            <NoResult />
        )
}

const CompanyListContent = () => {
    const companiesContext = useContext(CompaniesContext)
    const { companies, pagin, loading, setCurrentPage, filterList } = companiesContext
    const [currentName, setCurrentName] = useState('')
    const [currentEmail, setCurrentEmail] = useState('')
    const [currentVat, setCurrentVat] = useState('')

    // filters companies
    const handleSubmit = e => {
        e.preventDefault()
        filterList(currentName, currentEmail, currentVat)
    }

    return (
        <>
            <Title title="Le aziende nella rete" />
            <form onSubmit={e => handleSubmit(e)}>
                <FormGrid tcolumns={`repeat(3, 1fr)`} onSubmit={() => console.log('submit')}>
                    <Field>
                        <label>Ricerca per Nome</label>
                        <input type="text" onChange={e => setCurrentName(e.target.value)} />
                    </Field>
                    <Field>
                        <label>Ricerca per Email</label>
                        <input type="text" onChange={e => setCurrentEmail(e.target.value)} />
                    </Field>
                    <Field>
                        <label>Ricerca per P.IVA</label>
                        <input type="text" onChange={e => setCurrentVat(e.target.value)} />
                    </Field>
                    <FormActions>
                        <Button icon={IconFilter}>Filtra</Button>
                    </FormActions>
                </FormGrid>
            </form>
            {!loading
                ? <>
                    <PageInfo tot={pagin.total} curr={pagin.current_page} last={pagin.last_page} />
                    <Companies companies={companies} />
                    <Paginator pageCount={pagin.last_page} onPageChange={page => setCurrentPage(page + 1)} />
                </>
                : ''
            }
        </>
    )
}


const CompanyList = () => {
    return (
        <CompaniesProvider>
            <CompanyListContent />
        </CompaniesProvider>
    )
}

export default CompanyList