import React, { useState, useEffect, createContext } from 'react'
import axios from 'axios'
import api from '../api.json'
import { parseUrl } from '../scripts/util'

export const CompaniesContext = createContext()

export const CompaniesProvider = ({ children }) => {
    const [loading, setLoading] = useState(true)
    const [companies, setCompanies] = useState()
    const [pagin, setPagin] = useState({})
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        // build url and update company list
        updateList()
    }, [currentPage])

    // get or update companies list
    const fetchData = url => {
        axios.get(url, { withCredentials: true })
            .then(res => {
                // update company list
                setCompanies(res.data.data)
                // update paginator data
                setPagin(res.data.meta)
                // show list
                setLoading(false)
            })
    }
    
    // get company list
    const updateList = () => {
        const url = parseUrl(api.express.organization.company_list, [currentPage, '', '', '']);
        fetchData(url)
    }

    // update company list
    const filterList = (currentName, currentEmail, currentVat) => {
        const url = parseUrl(api.express.organization.company_list, [1, currentName, currentEmail, currentVat]);
        fetchData(url)
    }

    
    //Next step
    // const remove = id => {
    //     const url = parseUrl(api.express.organization.company_remove)
    //     axios.post(url, { id }, { withCredentials: true })
    //         .then(() => {
    //             updateList()
    //         })
    //         .catch(err => {
    //             console.log('err', err)
    //         })
    // }

    return (
        <CompaniesContext.Provider value={{
            loading,
            companies,
            pagin,
            // remove,
            updateList,
            filterList,
            setCurrentPage
        }}>
            {children}
        </CompaniesContext.Provider>
    )
}
