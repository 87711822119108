import React, { useContext } from 'react'
import { Switch, Route } from 'react-router-dom'
import styled from 'styled-components'
import { mq } from '../../styled/Global'
import { Button } from '../../styled/Cta'
import { IconChangeUser, IconUser } from '../../styled/Icons'
import { Title } from '../../components/PageTitle'
import { navs } from '../routesData'
import { AuthContext } from '../../contexts/AuthContext'
import { EmployeeProvider } from '../../contexts/EmployeeContext'
import PrivateRoute from '../../components/PrivateRoute'
import GdprCard from '../../components/cards/GdprCard'
import NotFound from '../NotFound'

const SelectCompanyCard = styled.div`
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${mq.smallOnly} {
        display: block;
        text-align: center;
        
        h1 {
            width: 100%;
            margin-bottom: 30px;
        }
    }
`

const SubUserWrap = styled.div`
    max-width: 900px;
    margin: 0 auto;
`

const EmployeeView = () => {
    const auth = useContext(AuthContext)
    const { user, selectUserImpersonification } = auth
    const { id, usersToImpersonate } = user   

    return user.terms ? (
        <EmployeeProvider>
            {id && usersToImpersonate
            ? <Switch>
                {navs[user.role].map((route, i) => 
                    <PrivateRoute key={i}
                        exact={route.path === '/' ? true : false} 
                        path={route.path} 
                        component={route.component} 
                    />
                )}
                <Route component={NotFound} />
              </Switch>
            : !usersToImpersonate
                ? ''
                : <SubUserWrap>
                    <Title icon={IconUser} title={user.impersonate.impersonate_type} />
                    {usersToImpersonate.map(el =>
                        <SelectCompanyCard key={el.company_id}>
                            <h1 className="title">{el.name}</h1>
                            <Button icon={IconChangeUser} onClick={() => selectUserImpersonification(el.company_id)}>Seleziona</Button>
                        </SelectCompanyCard>
                    )}
                </SubUserWrap>
            }
        </EmployeeProvider>
    ) : ( <GdprCard /> )
}

export default EmployeeView
