import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { color, mq } from './Global'
import { ButtonSpinner } from './Spinner'

export const BaseButton = ({ loading, className, icon: Icon, children, ...rest }) => {
    return (
        <button {...rest} className={`${className} ${Icon ? 'icon' : ''} ${loading ? 'isLoading' : ''}`}>
            {loading
                ? <ButtonSpinner />
                : ''
            }
            {Icon
                ? <Icon size={12} hex={color.white}/>
                : ''
            }
            <span>{children}</span>
        </button>
    )
}

export const Button = styled(BaseButton)`
    background: ${props => props.color ? props.color : color.blue};
    color: ${props => props.color ? color.blue : color.white};
    border: 0;
    padding: 8px 18px 9px 18px;
    border-radius: 20px;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
    min-width: 80px;
    text-align: center;
    margin-top: ${props => props.mt ? props.mt : `0`};
    margin-right: ${props => props.mr ? props.mr : `0`};
    margin-left: ${props => props.ml ? props.ml : `0`};
    margin-bottom: ${props => props.mb ? props.mb : `0`};
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &.isLoading {
        span {
            opacity: 0;
        }

        svg:nth-child(1) {
            opacity: 0;
        }
    }

    &.icon {
        span {
            margin-left: 10px;
        }

        svg {
            top: -2px;
            position: relative;
        }
    }
`;

export const ButtonLink = styled(BaseButton)`
    background: ${props => props.color ? props.color : color.blue};
    color: ${props => props.color ? color.blue : color.white};
    border: 0;
    padding: 8px 18px;
    border-radius: 15px;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
    min-width: 80px;
    text-align: center;
    margin-bottom: ${props => props.mb ? props.mb : `0`};
`;

export const StyledLink = styled(NavLink)`
    display: inline-block;
    text-decoration: none;
    color: ${props => props.color ? props.color : color.blue};
    margin-right: ${props => props.mr ? props.mr : '0'};
    margin-bottom: ${props => props.mb ? props.mb : '0'};
    margin-left: ${props => props.ml ? props.ml : '0'};
    font-size: 13px;
    text-transform: uppercase;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        width: calc(100% - 12px);
        background: ${color.white};
        height: 1px;
        left: 50%;
        transform: translateX(-50%);
        bottom: -9px;
        opacity: 0;
        transition: all .3s ease;
    }
    
    &.isActive,
    &:hover,
    &:focus {
        &:after {
            bottom: -6px;
            opacity: 1;
        }
    }

    @media ${mq.mediumDown} {
        &.isActive {
            font-weight: 600;
            pointer-events: none;
        }
    }
`
