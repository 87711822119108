import React, { useState, useContext } from 'react'
import { Switch, Route } from 'react-router-dom'
import styled from 'styled-components'
import { mq } from '../../styled/Global'
import { Button } from '../../styled/Cta'
import { Field } from '../../styled/Forms'
import { Paginator, PageInfo } from '../../styled/Pagination'
import { IconChangeUser, IconUser, IconSearch } from '../../styled/Icons'
import { Title } from '../../components/PageTitle'
import { navs } from '../routesData'
import { AuthContext } from '../../contexts/AuthContext'
import PrivateRoute from '../../components/PrivateRoute'
import NotFound from '../NotFound'

const Search = styled.form`
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
`

const SearchInput = styled(Field)`
    flex: 1 1 0px;
    margin-right: 15px;
`
const SearchAction = styled.div`
    flex: 0 0 auto;
`

const SelectCompanyCard = styled.div`
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${mq.smallOnly} {
        display: block;
        text-align: center;
        
        h1 {
            width: 100%;
            margin-bottom: 30px;
        }
    }
`

const SubUserWrap = styled.div`
    max-width: 900px;
    margin: 0 auto;
`

const OrganizationView = () => {
    const auth = useContext(AuthContext)
    const { user, selectUserImpersonification, updateCurrentPage, impersonateSearch } = auth
    const { id, role, usersToImpersonate, userToImpersonatePagin } = user
    const [currentSearch, setCurrentSearch] = useState('');
    const [searchLoading, setSearchLoading] = useState(false)

    const search = e => {
        e.preventDefault()
        setSearchLoading(true)
        impersonateSearch(currentSearch)
        setSearchLoading(false)
    }

    return (
        <>
        {id && usersToImpersonate
            ? <Switch>
                {navs[role].map((route, i) => 
                    <PrivateRoute key={i}
                        exact={route.path === '/' ? true : false} 
                        path={route.path} 
                        component={route.component} 
                    />
                )}
                <Route component={NotFound} />
            </Switch>
            : !usersToImpersonate
                ? ''
                : <SubUserWrap>
                    <Title icon={IconUser} title={user.impersonate.impersonate_type} />
                    <Search onSubmit={e => search(e)}>
                        <SearchInput mb="0" mr="15px">
                            <input type="text" placeholder="Cerca per nome" value={currentSearch} onChange={e => setCurrentSearch(e.target.value)} />
                        </SearchInput>
                        <SearchAction>
                            <Button loading={searchLoading} icon={IconSearch}>Cerca</Button>
                        </SearchAction>
                    </Search>
                    <PageInfo tot={userToImpersonatePagin.total} curr={userToImpersonatePagin.current_page} last={userToImpersonatePagin.last_page} />
                    {usersToImpersonate.map(el =>
                        <SelectCompanyCard key={el.company_id}>
                            <h1 className="title">{el.name}</h1>
                            <Button icon={IconChangeUser} onClick={() => selectUserImpersonification(el.company_id)}>Seleziona</Button>
                        </SelectCompanyCard>
                    )}
                    <Paginator pageCount={userToImpersonatePagin.last_page} onPageChange={page => updateCurrentPage(page + 1)} />
                </SubUserWrap>
        }
        </>
    )
}

export default OrganizationView
