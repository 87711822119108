import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import api from '../../api.json'
import { parseUrl } from '../../scripts/util'
import { color } from '../../styled/Global'
import { Field } from '../../styled/Forms'
import { Button } from '../../styled/Cta'
import { Callout } from '../../styled/Callout'
import { IconSearch, IconImport } from '../../styled/Icons'
import CompanyCard from '../../components/cards/CompanyCard'
import { Title } from '../../components/PageTitle';

const CenterWrapper = styled.div`
    max-width: 900px;
    margin: 0 auto;
`

const Search = styled.form`
    display: flex;
    align-items: flex-end;
`

const SearchInput = styled(Field)`
    flex: 1 1 0px;
    margin-right: 20px;
`
const SearchAction = styled.div`
    flex: 0 0 auto;
`

const Result = styled.div`
    margin-top: 30px;
`

const ErrorMsg = styled.div`
    color: ${color.error};
    font-size: 13px;
    font-weight: 600;
    margin-top: 5px;
    padding-left: 6px;
`

const FieldCheckBox = styled(Field)`
    margin-top: 24px;
`

const ImportCompany = () => {
    const [searchLoading, setSearchLoading] = useState(false)
    const [searchErrorMsg, setSearchErrorMsg] = useState(false)
    const [importSuccessMsg, setImportSuccessMsg] = useState(false)
    const [importErrorMsg, setImportErrorMsg] = useState(false)
    const [is_supplier, setIsSupplier] = useState(true)  // Company type supplier (1)
    const [is_provider, setIsProvider] = useState(true)  // Company type provider (2)
    const [companyType, setCompanyType] = useState(3)
    const [vat, setVat] = useState('')
    const [result, setResult] = useState('')

    // search company to system using vat
    const search = e => {
        e.preventDefault()
        if (vat === '') { return }

        setSearchLoading(true);
        resetMsgs();

        const url = parseUrl(api.express.organization.company_search)
        axios.post(url, { vat: vat }, { withCredentials: true })
            .then(res => {
                setResult({
                    id: res.data.IdCrm,
                    name: res.data.RagioneSociale,
                    email: res.data.EMail,
                    pec: res.data.Pec,
                    piva: res.data.PartitaIva,
                    address: {
                        website: res.data.SitoWeb,
                        phone: res.data.Telefono,
                        fax: res.data.Fax,
                        city: res.data.Comune,
                        province: res.data.Provincia,
                        street: res.data.Indirizzo,
                        street_number: res.data.Civico
                    },
                    type: 3
                })
                setSearchLoading(false)
            })
            .catch(err => {
                setSearchLoading(false)
                setResult('')
                setSearchErrorMsg(err.response.data)
            })
    }

    const importCompany = (vat, company_type) => {

        resetMsgs();

        if (company_type === 0){
            setImportErrorMsg('Selezionare almeno una tipologia!')
            return
        }

        const url = parseUrl(api.express.organization.company_import)
        axios.post(url, { vat: vat, company_type: company_type }, { withCredentials: true })
            .then(res => {
                setResult('')
                setVat('')
                setImportSuccessMsg('Success!')
            })
            .catch(err => {
                setImportErrorMsg(err.response.statusText)
            })
    }

    const resetMsgs = () => {
        setSearchErrorMsg(false)
        setImportSuccessMsg(false)
        setImportErrorMsg(false)
    }

    useEffect(() => {
        if ( !is_supplier && !is_provider ){
            setCompanyType(0)  // Error state company type
            return
        }

        if ( is_supplier && is_provider ){
            setCompanyType(3)  // Both
        }
        else{
            if ( is_supplier ){
                setCompanyType(1)  // Only supplier
            }
            else{
                setCompanyType(2)  // Only provider
            }
        }

    }, [is_supplier, is_provider])

    return (
        <CenterWrapper>
            <Title title="Importa azienda" />
            <Search onSubmit={e => search(e)}>
                <SearchInput mb="0" mr="15px">
                    <label>Ricerca per P.IVA (01944440245)</label>
                    <input type="text" value={vat} onChange={e => setVat(e.target.value)} />
                </SearchInput>
                <SearchAction>
                    <Button loading={searchLoading} icon={IconSearch}>Cerca</Button>
                </SearchAction>
            </Search>
            {searchErrorMsg
                ? <ErrorMsg>{searchErrorMsg}</ErrorMsg>
                : ''
            }
            <Result>
                {importSuccessMsg
                    ? <Callout status="success" title={importSuccessMsg} />
                    : ''
                }
                {importErrorMsg
                    ? <Callout status="error" title={importErrorMsg} />
                    : ''
                }
                {result
                    ?   <>
                        <CompanyCard key={result.id}
                            id={result.id}
                            name={result.name}
                            email={result.email}
                            pec={result.pec}
                            piva={result.piva}
                            address={result.address}
                            showInfo={true}
                            registered={false}
                            invited={false}
                            import={true}
                            type={result.type}
                            buttonIcon={IconImport}
                            buttonText="Importa"
                            buttonAction={() => importCompany(result.piva, companyType)}
                        />
                        <FieldCheckBox checkbox key="is_supplier">
                            <input id="is_supplier" name="is_supplier" checked={is_supplier} type="checkbox" value={is_supplier} onChange={e => setIsSupplier(e.target.checked)} />
                            <div className="fake-input"></div>
                            <label htmlFor="is_supplier">Erogatore</label>
                        </FieldCheckBox>
                        <FieldCheckBox checkbox key="is_provider">
                            <input id="is_provider" name="is_provider" checked={is_provider} type="checkbox" value={is_provider} onChange={e => setIsProvider(e.target.checked)} />
                            <div className="fake-input"></div>
                            <label htmlFor="is_provider">Fornitore</label>
                        </FieldCheckBox>
                        </>
                    : ''
                }
            </Result>
        </CenterWrapper>
    )
}

export default ImportCompany
