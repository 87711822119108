import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { color } from '../../styled/Global'

const StyledCard = styled(Link)`
    background: ${color.blue};
    border-radius: 4px;
    z-index: 1;
    position: relative;
    padding: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
    text-align: center;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    text-decoration: none;

    .icon {
        flex: 0 0 auto;
        margin-bottom: 20px;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
    }
`

const ServiceName = styled.h1`
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    margin-left: 30px;
    margin-right: 30px;
    color: ${color.white};
`

const ShortDescription = styled.p`
    font-size: 14px;
    color: ${color.white};
    margin-bottom: 30px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`

const ImageWrapper = styled.div`
    height: 85px;
    width: 85px;
    margin: 0 auto 20px auto;
    position: relative;
`

const CategoryCard = (props) => {

    return (
        <StyledCard to={props.special ? '/expense-report' : '/services/' + props.id}>
            <ImageWrapper>
            { 
                props.icon && <img className="icon" src={'data:image/svg+xml;base64,' + props.icon} />
            }
            </ImageWrapper>            
            <div>
                <ServiceName>{props.name}</ServiceName>
                <ShortDescription>{props.desc}</ShortDescription>
            </div>
        </StyledCard>
    );
}

export default CategoryCard
 