import React, { useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'

import api from '../../api.json'
import { parseUrl } from '../../scripts/util'
import { color } from '../../styled/Global'
import { Field } from '../../styled/Forms'
import { Button } from '../../styled/Cta'
import { Callout } from '../../styled/Callout'
import OrganizationCard from '../../components/cards/OrganizationCard'
import { Title } from '../../components/PageTitle'

const CenterWrapper = styled.div`
    max-width: 900px;
    margin: 0 auto;
`

const Search = styled.form`
    display: flex;
    align-items: flex-end;
`

const SearchInput = styled(Field)`
    flex: 1 1 0px;
    margin-right: 20px;
`
const SearchAction = styled.div`
    flex: 0 0 auto;
`

const Result = styled.div`
    margin-top: 30px;
`

const ErrorMsg = styled.div`
    color: ${color.error};
    font-size: 13px;
    font-weight: 600;
    margin-top: 5px;
    padding-left: 6px;
`

const ImportOrganization = () => {
    const [searchErrorMsg, setSearchErrorMsg] = useState(false)
    const [importSuccessMsg, setImportSuccessMsg] = useState(false)
    const [importErrorMsg, setImportErrorMsg] = useState(false)
    const [vat, setVat] = useState('')
    const [result, setResult] = useState('')

    // search organization to system using vat
    const search = e => {
        e.preventDefault()
        if (vat === '' ) { return }

        resetMsgs();

        const url = parseUrl(api.express.admin.organization_search)
        axios.post(url, { vat: vat }, { withCredentials: true })
            .then(res => {
                setResult({
                    id: res.data.IdOrganizzazione,
                    name: res.data.denominazione,
                    email: res.data.email,
                    piva: res.data.partitaIva
                })
            })
            .catch(err => {
                setResult('')
                setSearchErrorMsg(err.response.data)
            })
    }

    const importOrganization = vat => {
        resetMsgs();
        const url = parseUrl(api.express.admin.organization_import)
        axios.post(url, { vat: vat }, { withCredentials: true })
            .then(res => {
                setResult('')
                setVat('')
                setImportSuccessMsg('Success!')
            })
            .catch(err => {
                setImportErrorMsg(err.response.statusText)
            })
    }

    const resetMsgs = () => {
        setSearchErrorMsg(false)
        setImportSuccessMsg(false)
        setImportErrorMsg(false)
    }

    return (
        <CenterWrapper>
            <Title title="Importa organizzazione" />
            <Search onSubmit={e => search(e)}>
                <SearchInput mb="0" mr="15px">
                    <label>Ricerca per P.IVA (01944440245)</label>
                    <input type="text" value={vat} onChange={e => setVat(e.target.value)} />
                </SearchInput>
                <SearchAction>
                    <Button>Cerca</Button>
                </SearchAction>
            </Search>
            {searchErrorMsg
                ? <ErrorMsg>{searchErrorMsg}</ErrorMsg>
                : ''
            }
            <Result>
                {importSuccessMsg
                    ? <Callout status="success" title={importSuccessMsg} />
                    : ''
                }
                {importErrorMsg
                    ? <Callout status="error" title={importErrorMsg} />
                    : ''
                }
                {result
                    ? <OrganizationCard key={result.id}
                        id={result.id}
                        name={result.name}
                        email={result.email}
                        piva={result.piva}
                        showInfo={true}
                        registered={false}
                        invited={false}
                        import={true}
                        buttonText="Importa"
                        buttonAction={() => importOrganization(result.piva)}
                    />
                    : ''
                }
            </Result>
        </CenterWrapper>
    )
}

export default ImportOrganization
