import React, { useContext } from 'react'
import styled from 'styled-components'

import { EmployeeContext } from '../../contexts/EmployeeContext'
import { color, mq } from '../../styled/Global'
import { IconEuro, IconList } from '../../styled/Icons'
import { Title } from '../../components/PageTitle';
import Requests from './Requests'

const Dashboard = styled.div`
    max-width: 900px;
    margin: 0 auto;
`

const Quotes = styled.div`
    margin-bottom: 50px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, 300px);

    @media ${mq.smallOnly} {
        grid-template-columns: 1fr;
    }
`

const QuoteCard = styled.div`
    background: ${props => !(props.empty === 0) ? color.white : 'rgba(255, 255, 255, .3)'};
    border-radius: 4px;
    box-shadow: ${props => !(props.empty === 0) ? '0 3px 8px rgba(0, 0, 0, 0.3)' : '0 1px 3px rgba(0,0,0,.3)'};
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .id {
        display: block;
        text-align: inherit;
        margin-bottom: 10px;
        color: ${color.darkGray};
        font-size: 12px;
    }

    .provider {
        display: block;
        text-align: inherit;
        color: ${props => !(props.empty === 0) ? color.blue : color.darkGray};
        font-weight: 600;
        line-height: 1.4;
        margin-bottom: 15px;
    }

    .quote {
        font-size: 14px;

        .value {
            display: inline-block;
            border-radius: 25px;
            padding: 4px 8px;
            font-weight: 600;
            color: ${color.white};
            background: ${props => !(props.empty === 0) ? color.blue : color.darkGray};
            margin-right: 10px;
        }
    }
`

const Profile = () => {
    const employeeContext = useContext(EmployeeContext)
    const { employee } = employeeContext

    return (
        <Dashboard>
            {employee.quotes
                ? <>
                    <Title icon={IconEuro} title={employee.quotes.length > 1 ? 'I tuoi saldi' : 'Il tuo saldo'} />
                    <Quotes>
                        {employee.quotes.map(quote =>
                            <QuoteCard key={quote.welfare_quote_id} empty={quote.threshold - quote.value}>
                                <div>
                                    <span className="id">ID: <strong>{quote.welfare_quote_id}</strong></span>
                                    <h3 className="provider">{quote.supplier.name} ({quote.year})</h3>
                                </div>
                                <div className="quote">
                                    <span className="value">{quote.threshold - quote.value} €</span>
                                    <span className="threshold">di {quote.threshold} €</span>
                                </div>
                            </QuoteCard>
                        )}
                    </Quotes>
                </>
                : ''
            }
            <Title icon={IconList} title="Le tue richieste" />
            <Requests />
        </Dashboard>
    )
}

export default Profile
