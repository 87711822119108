"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.manuals = void 0;
var manuals = {
    "viewBox": "0 0 84.5 84.5",
    "children": [{
        "name": "g",
        "attribs": {},
        "children": [{
            "name": "path",
            "attribs": {
                "d": "M75.347,43.103c0.766-1.006,1.188-2.492,1.188-4.184c0-2.404-0.834-4.34-2.176-5.053\n" +
                    "\tc-0.051-0.025-38.31-15.078-38.31-15.078c-0.156-0.082-0.331-0.123-0.51-0.123c-0.18,0-0.358,0.041-0.516,0.123L9.112,30.894\n" +
                    "\tc-0.375,0.188-0.608,0.566-0.608,0.99c0,0.426,0.232,0.807,0.613,0.996l0.913,0.473c0.063,0.033,0.116,0.084,0.148,0.146\n" +
                    "\tc0.281,0.537,0.618,1.463,0.618,2.828c0,1.645-0.484,2.633-0.773,3.076c-0.064,0.1-0.174,0.16-0.292,0.16\n" +
                    "\tc-0.009,0-0.017-0.002-0.026-0.002c-0.028-0.002-0.056-0.004-0.083-0.004c-0.42,0-0.801,0.234-0.995,0.609\n" +
                    "\tc-0.137,0.266-0.161,0.568-0.069,0.854c0.092,0.286,0.29,0.518,0.557,0.654l0.913,0.473c0.063,0.033,0.116,0.086,0.148,0.148\n" +
                    "\tc0.281,0.535,0.618,1.461,0.618,2.827c0,1.201-0.282,2.321-0.773,3.076c-0.061,0.102-0.171,0.158-0.289,0.158\n" +
                    "\tc-0.009,0-0.017,0-0.026,0c-0.028-0.002-0.056-0.004-0.083-0.004c-0.42,0-0.801,0.236-0.995,0.611\n" +
                    "\tc-0.137,0.266-0.161,0.568-0.069,0.852c0.092,0.287,0.29,0.52,0.556,0.656L46.857,66.25c0.162,0.084,0.336,0.125,0.515,0.125\n" +
                    "\tc0.182,0,0.354-0.041,0.516-0.123L73.62,53.017c0.031-0.017,0.062-0.026,0.096-0.031c1.66-0.315,2.82-2.481,2.82-5.271\n" +
                    "\tc0-1.695-0.424-3.182-1.188-4.191C75.25,43.399,75.25,43.226,75.347,43.103z M10.793,39.909c-0.028-0.09-0.017-0.188,0.029-0.27\n" +
                    "\tc0.484-0.875,0.751-2.051,0.751-3.312c0-0.521-0.054-1.043-0.17-1.641c-0.026-0.131,0.025-0.266,0.133-0.346\n" +
                    "\tc0.061-0.045,0.135-0.07,0.209-0.07c0.053,0,0.109,0.014,0.158,0.039l34.956,14.35c0.093,0.045,0.185,0.078,0.274,0.098\n" +
                    "\tc0.312,0.599,0.677,1.621,0.677,3.14c0,1.584-0.396,2.612-0.707,3.181L10.967,40.12C10.884,40.073,10.821,39.999,10.793,39.909z\n" +
                    "\t M10.967,48.915c-0.083-0.045-0.146-0.119-0.174-0.211c-0.028-0.088-0.017-0.186,0.029-0.268c0.484-0.877,0.751-2.053,0.751-3.314\n" +
                    "\tc0-0.52-0.054-1.043-0.17-1.641c-0.026-0.133,0.025-0.264,0.133-0.344c0.061-0.049,0.135-0.07,0.209-0.07\n" +
                    "\tc0.053,0,0.109,0.012,0.158,0.039l34.963,14.354c0.08,0.043,0.168,0.074,0.26,0.095c0.312,0.594,0.685,1.622,0.685,3.153\n" +
                    "\tc0,1.578-0.392,2.603-0.701,3.172L10.967,48.915z M73.549,50.696c-0.053,0.061-0.127,0.102-0.207,0.113\n" +
                    "\tc-0.02,0.008-0.053,0.014-0.092,0.016c-0.051,0.006-0.119,0.023-0.184,0.041c-0.01,0.004-0.016,0.004-0.023,0.008l-0.047,0.01\n" +
                    "\tc-0.07,0.017-0.137,0.029-0.184,0.052c-0.01,0.004-0.021,0.01-0.033,0.015c-0.012,0.002-0.023,0.006-0.035,0.01L48.032,63.667\n" +
                    "\tc0.275-0.68,0.525-1.656,0.525-2.959c0-1.498-0.33-2.572-0.652-3.262l24.98-12.842c0.051-0.025,0.104-0.037,0.16-0.037\n" +
                    "\tc0.055,0,0.1,0.008,0.133,0.018c0.051,0.004,0.098,0.021,0.143,0.045c0.396,0.224,0.982,1.359,0.982,3.084\n" +
                    "\tC74.304,49.458,73.7,50.524,73.549,50.696z M73.549,41.899c-0.055,0.062-0.129,0.104-0.211,0.115\n" +
                    "\tc-0.024,0.008-0.049,0.014-0.077,0.016c-0.033,0.002-0.08,0.012-0.13,0.025c-0.032,0.008-0.065,0.016-0.094,0.021l-0.033,0.008\n" +
                    "\tc-0.075,0.018-0.143,0.031-0.19,0.053c-0.01,0.006-0.021,0.01-0.032,0.014c-0.013,0.004-0.023,0.008-0.035,0.01L48.026,54.868\n" +
                    "\tc0.275-0.68,0.531-1.66,0.531-2.973c0-1.488-0.328-2.559-0.645-3.248l24.973-12.846c0.051-0.023,0.105-0.037,0.16-0.037\n" +
                    "\tc0.02,0,0.037,0,0.057,0.004c0.027,0.004,0.053,0.012,0.084,0.02c0.049,0.008,0.098,0.021,0.141,0.049\n" +
                    "\tc0.396,0.232,0.978,1.373,0.978,3.082C74.304,40.663,73.7,41.729,73.549,41.899z"
            },

        }]
    }]
};
exports.manuals = manuals;