import React, { useState, useContext } from 'react'
import { Switch, Route } from 'react-router-dom'
import styled from 'styled-components'
import { navs } from '../routesData'
import { AuthContext } from '../../contexts/AuthContext'
import {color, mq} from '../../styled/Global'
import { Field } from '../../styled/Forms'
import { Button } from '../../styled/Cta'
import {IconChangeUser, IconError, IconUser, IconSearch} from '../../styled/Icons'
import { Title } from '../../components/PageTitle'
import PrivateRoute from '../../components/PrivateRoute'
import NotFound from '../NotFound'
import { Paginator, PageInfo } from '../../styled/Pagination'


const SelectCompanyCard = styled.div`
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${mq.smallOnly} {
        display: block;
        text-align: center;
        
        h1 {
            width: 100%;
            margin-bottom: 30px;
        }
    }
`

const SelectEmployeeCard = styled.div`
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${mq.smallOnly} {
        display: block;
        text-align: center;
        
        h1 {
            width: 100%;
            margin-bottom: 30px;
        }
    }
`

const SubUserWrap = styled.div`
    max-width: 900px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
`


const StyledNoResult = styled.div`
    display: flex;
    align-items: center;

    > div {
        margin: 0 auto;
        max-width: 400px;
        text-align: center;
    }

    .icon {
        margin-bottom: 15px;
    }
`

const Search = styled.form`
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
`

const SearchInput = styled(Field)`
    flex: 1 1 0px;
    margin-right: 15px;
`
const SearchAction = styled.div`
    flex: 0 0 auto;
`

const NoResult = () => {
    return (
        <StyledNoResult>
            <div>
                <IconError className="icon" hex={color.error} size={30} />
                <span>Nessun risultato</span>
            </div>
        </StyledNoResult>
    )
}

const CompanyRoutes = ({ type, role }) => {
    return (
        <Switch>
            {navs[role][type].map((route, i) =>
                <PrivateRoute key={i}
                              exact={route.path === '/'}
                              path={route.path}
                              component={route.component}
                />
            )}
            <Route component={NotFound} />
        </Switch>
    )
};

const Employees = ({ employees, selectUserImpersonification }) => {

    return employees.length ? (
        <>
            <SubUserWrap>
                {employees.map(el =>
                    <SelectEmployeeCard key={el.employee_id}>
                        <h1 className="title">{el.lastname} {el.firstname}</h1>
                        <Button icon={IconChangeUser} onClick={() => selectUserImpersonification(el.employee_id)}>Seleziona</Button>
                    </SelectEmployeeCard>
                )}
            </SubUserWrap>
        </>
    ) : (
        <NoResult />
    )
};

const CompanyView = () => {
    const auth = useContext(AuthContext)
    const { user, selectUserImpersonification, selectCompany, updateCurrentPage, impersonateSearch } = auth
    const { id, type, role, companies, usersToImpersonate, userToImpersonatePagin } = user
    const [currentSearch, setCurrentSearch] = useState('');
    const [searchLoading, setSearchLoading] = useState(false)

    const search = e => {
        e.preventDefault()
        setSearchLoading(true)
        impersonateSearch(currentSearch)
        setSearchLoading(false)
    }

    return (
        <>
            {id
                ? <CompanyRoutes type={type} role={role} />
                : companies || usersToImpersonate
                    ? <>
                        {companies.length
                            ? <SubUserWrap>
                                <Title icon={IconUser} title="Seleziona utente" />
                                {companies.map(el =>
                                    <SelectCompanyCard key={el.company_id}>
                                        <h1 className="title">{el.name}</h1>
                                        <Button icon={IconChangeUser} onClick={() => selectCompany(el.company_id)}>Seleziona</Button>
                                    </SelectCompanyCard>
                                )}
                            </SubUserWrap>
                            : ''
                        }
                        {usersToImpersonate.length || user.type // user.type is used to see if user has impersonification data
                            ? <>
                                <SubUserWrap>
                                    <Title icon={IconUser} title={user.impersonate.impersonate_type}/>
                                    <Search onSubmit={e => search(e)}>
                                        <SearchInput mb="0" mr="15px">
                                            <input type="text" placeholder="Cerca per cognome" value={currentSearch} onChange={e => setCurrentSearch(e.target.value)} />
                                        </SearchInput>
                                        <SearchAction>
                                            <Button loading={searchLoading} icon={IconSearch}>Cerca</Button>
                                        </SearchAction>
                                    </Search>
                                    <PageInfo tot={userToImpersonatePagin.total} curr={userToImpersonatePagin.current_page} last={userToImpersonatePagin.last_page} />
                                    <Employees employees={usersToImpersonate} selectUserImpersonification={selectUserImpersonification}/>
                                    <Paginator pageCount={userToImpersonatePagin.last_page} onPageChange={page => updateCurrentPage(page + 1)} />
                                </SubUserWrap>
                            </>
                            : ''
                        }
                    </>
                    : ''
            }
        </>
    )
};

export default CompanyView
