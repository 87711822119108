import React, { useState } from 'react';
import styled from 'styled-components';
import { color, mq } from '../../styled/Global';
import { Button } from '../../styled/Cta';
import { IconTrash, IconCheck } from "../../styled/Icons";
import { ConfirmModal, ConfirmModalReason } from "../../styled/Modal";

const StyledCardEmployee = styled.div`
    padding: 0;
    background: ${color.white};
    display: grid;
    grid-template-columns: 1fr 120px 200px 120px;
    align-items: center;
    padding: 20px 15px;
    line-height: 1.4;
    
    @media ${mq.mediumOnly} {
        grid-template-columns: 1fr 1fr;
    }

    @media ${mq.smallOnly} {
        grid-template-columns: 1fr;
    }

    .label-for-small {
        display: none;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-size: 14px;

        @media ${mq.mediumDown} {
            display: block;
        }
    }

    &:nth-child(even) {
        background: #e5e7ea;
    }

    .service-name {
        font-weight: 600;
        font-size: 14px;
        color: ${color.black}
    }

    .service-id,
    .token {
        display: block;
        font-size: 13px;
        color: ${color.darkGray}
    }

    .submitter-name {
        display: block;
    }

    .request-date {
        display: block;
        font-size: 13px;
        color: ${color.darkGray}
    }

    .request-description {
        display: block;
        font-size: 13px;
        color: ${color.darkGray}
    }

    .title {
        @media ${mq.smallOnly} {
            padding-bottom: 10px;
            margin-bottom: 10px;
        }
    }

    .submitter {
        @media ${mq.smallOnly} {
            padding-bottom: 10px;
        }
    }

    .quote,
    .status {
        justify-self: center;

        @media ${mq.mediumDown} {
            margin-top: 15px;
            justify-self: self-start;
        }
    }

    .action {
        justify-self: end;
        text-align: right;

        @media ${mq.mediumDown} {
            margin-top: 15px;
            justify-self: self-start;
        }
    }
`

const StyledCardOrganization = styled.div`
    padding: 0;
    background: ${color.white};
    display: grid;
    grid-template-columns: 1fr 1fr 120px 120px 120px;
    align-items: center;
    padding: 20px 15px;
    line-height: 1.4;
    
    @media ${mq.mediumOnly} {
        grid-template-columns: 1fr 1fr;
    }

    @media ${mq.smallOnly} {
        grid-template-columns: 1fr;
    }

    .label-for-small {
        display: none;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-size: 14px;

        @media ${mq.mediumDown} {
            display: block;
        }
    }

    &:nth-child(even) {
        background: #e5e7ea;
    }

    .service-name {
        font-weight: 600;
        font-size: 14px;
        color: ${color.black}
    }

    .service-id,
    .token {
        display: block;
        font-size: 13px;
        color: ${color.darkGray}
    }

    .submitter-name {
        display: block;
    }

    .request-date,
    .request-description,
    .submitter-taxcode,
    .submitter-email,
    .submitter-reason {
        display: block;
        font-size: 13px;
        color: ${color.darkGray}
    }

    .title {
        @media ${mq.smallOnly} {
            padding-bottom: 10px;
            margin-bottom: 10px;
        }
    }

    .submitter {
        > span {
            display: block;
        }

        @media ${mq.smallOnly} {
            padding-bottom: 10px;
        }
    }

    .quote,
    .status,
    .action {
        justify-self: center;

        @media ${mq.mediumDown} {
            margin-top: 15px;
            justify-self: self-start;
        }
    }

    .action {
        button:not(:last-of-type) {
            margin-bottom: 10px;

            @media ${mq.mediumDown} {
                margin-right: 10px;
            }
        }
    }
`

const StatusTicket = styled.span`
    position: relative;
    display: inline-block;
    padding: 3px 12px;
    font-size: 12px;
    letter-spacing: 1px;
    border-radius: 15px;
    color: ${color.white};

    &.cancelled {
        background: ${color.error}
    }

    &.rejected {
        background: ${color.error}
    }
    
    &.accepted {
        background: ${color.success}
    }

    &.pending {
        background: ${color.warning}
    }

    &.closed {
        background: ${color.error}
    }
`

const Quote = styled.div`
    font-size: 13px;
    display: inline-block;
    border-radius: 25px;
    padding: 4px 10px;
    font-weight: 600;
    color: ${color.white};
    background: ${color.blue};
`

const getStatusLabel = (status) => {
    switch (status) {
        case 'pending': return 'In attesa';
        case 'cancelled': return 'Cancellato';
        case 'rejected': return 'Rifiutato';
        case 'accepted': return 'Accettato';
        case 'close': default: return 'Chiuso';
    }
}

export const ExpenseReportCardOrganization = props => {
    const [acceptModalState, setAcceptModalState] = useState(false)
    const [rejectModalState, setRejectModalState] = useState(false)
    const [reason, setReason] = useState('')

    const onDownload = e => {
        e.preventDefault();
        props.handleDownload(props.id);
    };

    const onAccept = e => {
        e.preventDefault();
        props.handleAccept(props.id, setAcceptModalState);
    };

    const onReject = e => {
        e.preventDefault();
        props.handleReject(props.id, reason, setRejectModalState);
    };

    return (
        <StyledCardOrganization>
            <div className="submitter">
                <h1 className="service-name">ID: {props.id}</h1>
                <span className="submitter-name">{props.employee.firstname} {props.employee.lastname}</span>
                <span className="submitter-email">{props.employee.email}</span>
                <span className="submitter-taxcode">{props.employee.tax_code}</span>
                {props.status === "rejected"
                    ?   <span className="submitter-reason">{props.rejection_reason}</span>
                    : ''
                }
                <span className="request-description">{props.description}</span>
                <span className="request-date">{props.created}</span>
            </div>
            <div>
                <span className="company-name">{props.company.name}</span>
                <span className="service-id">P.IVA: {props.company.vat}</span>
            </div>
            <div className="quote">
                <Quote>{props.price} €</Quote>
            </div>
            <div className="status">
                <StatusTicket className={`${props.status}`}>{getStatusLabel(props.status)}</StatusTicket>
            </div>
            <div className="action">
                <Button onClick={(e) => onDownload(e)} >Download</Button>
                {props.status === "pending"
                    ? <>
                        <Button icon={IconCheck} onClick={() => setAcceptModalState(!acceptModalState)} >Accetta</Button>
                        <ConfirmModal
                            msg={`Sei sicuro di voler accettare il servizio a rimborso ${props.id}?`}
                            isOpen={acceptModalState}
                            onBackgroundClick={() => setAcceptModalState(!acceptModalState)}
                            onEscapeKeydown={() => setAcceptModalState(!acceptModalState)}
                            onConfirm={(e) => onAccept(e)}
                            onCancel={() => setAcceptModalState(!acceptModalState)}
                        />
                        <Button icon={IconTrash} onClick={() => setRejectModalState(!rejectModalState)}>Rifiuta</Button>
                        <ConfirmModalReason
                            reason={reason}
                            setReason={setReason}
                            msg={`Sei sicuro di voler rifiutare il servizio a rimborso ${props.id}?`}
                            isOpen={rejectModalState}
                            onBackgroundClick={() => setRejectModalState(!rejectModalState)}
                            onEscapeKeydown={() => setRejectModalState(!rejectModalState)}
                            onConfirm={(e) => onReject(e)}
                            onCancel={() => setRejectModalState(!rejectModalState)}
                        />
                    </>
                    : ''
                }
            </div>
        </StyledCardOrganization>
    );
};

export const ExpenseReportCardEmployee = props => {
    const [deleteModalState, setDeleteModalState] = useState(false);

    const onDownload = e => {
        e.preventDefault();
        props.handleDownload(props.id);
    };

    const onDelete = e => {
        e.preventDefault();
        props.handleDelete(props.id, setDeleteModalState);
    };

    return (
        <StyledCardEmployee>
            {/* <div className="title">
            </div> */}
            <div className="submitter">
                <h1 className="service-name">ID: {props.id}</h1>
                <span className="request-description">{props.description}</span>
                <span className="request-date">{props.created}</span>
            </div>
            <div className="quote">
                <Quote>{props.price} €</Quote>
            </div>
            <div className="status">
                <StatusTicket className={`${props.status}`}>{getStatusLabel(props.status)}</StatusTicket>
            </div>
            <div className="action">
                <Button mb="15px" onClick={(e) => onDownload(e)} >Download</Button>
                {props.status === "pending"
                    ? <>
                        <Button ml="10px" icon={IconTrash} onClick={() => setDeleteModalState(!deleteModalState)}>Elimina</Button>
                        <ConfirmModal
                            msg={`Sei sicuro di voler eliminare il servizio a rimborso ${props.id}?`}
                            isOpen={deleteModalState}
                            onBackgroundClick={() => setDeleteModalState(!deleteModalState)}
                            onEscapeKeydown={() => setDeleteModalState(!deleteModalState)}
                            onConfirm={(e) => onDelete(e)}
                            onCancel={() => setDeleteModalState(!deleteModalState)}
                        />
                    </>
                    : ''
                }
            </div>
        </StyledCardEmployee>
    );
};