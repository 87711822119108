import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import axios from 'axios'

import { EmployeeContext } from '../../contexts/EmployeeContext'
import api from '../../api.json'
import { parseUrl } from '../../scripts/util'
import { color } from '../../styled/Global'
import { Button } from '../../styled/Cta'
import { Modal } from '../../styled/Modal'
import { Field, Legend } from '../../styled/Forms'
import { IconPlus } from '../../styled/Icons'
import { Callout } from '../../styled/Callout'

const StyledCard = styled.div`
    background: ${color.white};
    border-radius: 4px;
    z-index: 1;
    position: relative;
    padding: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
    text-align: center;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
`;

const ServiceName = styled.h1`
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
    color: ${color.blue};
`

const ShortDescription = styled.p`
    font-size: 14px;
    color: ${color.darkGray};
    margin-bottom: 30px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`

const ShortAddress = styled.p`
    font-size: 14px;
    color: ${color.darkGray};
    margin-bottom: 30px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`

const Description = styled.p`
    font-size: 14px;
    color: ${color.darkGray};
    margin-bottom: 30px;
    margin-top: 30px;
`

const Address = styled.p`
    font-size: 14px;
    color: ${color.darkGray};
    margin-bottom: 30px;
    margin-top: 30px;
`

const FormGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin: 0 auto 30px auto;
    max-width: 900px;
`

const Radios = styled.div``

const FormActions = styled.div`
    text-align: center;

    button {
        margin: 0 10px;
    }
`

const ServiceCard = (props) => {
    const employeeContext = useContext(EmployeeContext)
    const { updateQuotes } = employeeContext
    const [paymentVal, setPaymentVal] = useState('')
    const [selectedQuote, setSelectedQuote] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [successMsg, setSuccessMsg] = useState()
    const [errorMsg, setErrorMsg] = useState()
    const [localLoading, setLocalLoading] = useState(false)


    const handleSubmit = e => {
        e.preventDefault()
        if (!paymentVal || !selectedQuote || localLoading) { return }
        setLocalLoading(true)
        setSuccessMsg(false)
        setErrorMsg(false)
        const quoteObj = props.quotes.filter(el => el.value === selectedQuote * 1)
        const data = {
            available_welfare: quoteObj[0].available,
            requested_value: paymentVal,
            service_id: props.id,
            welfare_quote_id: selectedQuote
        }
        const url = parseUrl(api.express.employee.create_request)
        axios.post(url, data, { withCredentials: true })
            .then(response => {
                setLocalLoading(false)
                setSuccessMsg('Richiesta effettuata!')
            })
            .catch(error => {
                setLocalLoading(false)
                setErrorMsg('Qualcosa è andato storto!')
                console.log('error', error);
            })
            .finally(() => {
                // Always update the employee context (quotes and service list data)
                updateQuotes()
            })
    }

    return (
        <StyledCard>
            <div>
                <ServiceName>{props.name}</ServiceName>
                <ShortDescription>{props.desc}</ShortDescription>
                <ShortAddress>{props.address}</ShortAddress>
            </div>
            <div>
                <Button icon={IconPlus} onClick={() => setIsOpen(!isOpen)}>Richiedi</Button>
                <Modal
                    title={props.name}
                    isOpen={isOpen}
                    close={() => setIsOpen(!isOpen)}
                >
                    {successMsg
                        ? <Callout status="success" title={successMsg} />
                        : ''
                    }
                    {errorMsg
                        ? <Callout status="error" title={errorMsg} />
                        : ''
                    }
                    <div>
                        <Description>{props.desc}</Description>
                    </div>
                    <div>
                        <Address>{props.address}</Address>
                    </div>
                    <form onSubmit={e => handleSubmit(e)}>
                        <FormGrid>
                            <Legend>Welfare</Legend>
                            {props.quotes
                                ? <Radios>
                                    {props.quotes.map((q, i) =>
                                        <Field radio key={i}>
                                            <input name="category" required={i === 0 ? true : false} id={q.value} type="radio" value={q.value} onChange={e => setSelectedQuote(e.target.value)} />
                                            <div className="fake-input"></div>
                                            <label htmlFor={q.value}>{q.label} - Welfare disponibile {q.available}€</label>
                                        </Field>
                                    )}
                                </Radios>
                                : ''
                            }
                            <Field>
                                <label>Valore (€)</label>
                                <input required type="number" value={paymentVal} onChange={e => setPaymentVal(e.target.value)} />
                            </Field>
                        </FormGrid>
                        <FormActions>
                            <Button loading={localLoading} icon={IconPlus}>Richiedi</Button>
                        </FormActions>
                    </form>
                </Modal>
            </div>
        </StyledCard>
    );
}

export default ServiceCard
