import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import axios from "axios"
import api from '../../api.json';
import { parseUrl } from '../../scripts/util'
import { Button } from '../../styled/Cta'
import { Field } from '../../styled/Forms'
import { mq } from '../../styled/Global'
import { Callout as BaseCallout } from '../../styled/Callout'
import { CompaniesContext } from '../../contexts/Organization.CompaniesContext'

const Callout = styled(BaseCallout)`
    margin-bottom: 30px;
`

const FieldCheckBox = styled(Field)`
    margin-top: 24px;
`

const FormGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${props => props.gap ? props.gap : `20px`};
    margin: 0 auto 30px auto;
    max-width: 900px;

    @media ${mq.smallOnly} {
        grid-template-columns: 1fr;
    }
`

const FormActions = styled.div`
    text-align: center;

    button {
        margin: 0 10px;
    }
`

const CompanyEditForm = (props) => {
    const companiesContext = useContext(CompaniesContext)
    const { updateList } = companiesContext

    const [errorMsg, setErrorMsg] = useState(false)
    const [successMsg, setSuccessMsg] = useState(false)

    const [is_supplier, setIsSupplier] = useState(true ? props.type === 'both' || props.type === 'supplier' : false)  // Company type supplier (1)
    const [is_provider, setIsProvider] = useState(true ? props.type === 'both' || props.type === 'provider' : false)  // Company type provider (2)
    const [companyType, setCompanyType] = useState('both')

    // modify company type
    const handleSubmit = e => {
        e.preventDefault()

        const data = {
            type: companyType
        }

        if (!is_supplier && !is_provider) {
            setSuccessMsg(false)
            setErrorMsg('Selezionare almeno una tipologia!')
            return
        }

        const url = parseUrl(api.express.organization.company_update)
        axios.post(url, { id: props.id, data: data }, { withCredentials: true })
            .then(res => {
                setErrorMsg(false)
                updateList()
                setSuccessMsg("Compagnia aggiornata con successo")
            })
            .catch(error => {
                setSuccessMsg(false)
                setErrorMsg('Errore durante il salvataggio delle modifiche! Contattare il supporto.')
            })
    }

    useEffect(() => {
        setErrorMsg(false)

        if ( is_supplier && is_provider ){
            setCompanyType('both')
        }
        else{
            if (is_supplier){
                setCompanyType('supplier')
            }
            else{
                setCompanyType('provider')
            }
        }
    }, [is_supplier, is_provider])


    return (
        <form onSubmit={e => handleSubmit(e)}>
            {errorMsg
                ? <Callout status="error" title={errorMsg} />
                : ''
            }
            {successMsg
                ? <Callout status="success" title={successMsg} />
                : ''
            }
            <FormGrid>
                <FieldCheckBox checkbox key="is_supplier">
                    <input id="is_supplier" name="is_supplier" checked={is_supplier} type="checkbox" value={is_supplier} onChange={e => setIsSupplier(e.target.checked)} />
                    <div className="fake-input"></div>
                    <label htmlFor="is_supplier">Erogatore</label>
                </FieldCheckBox>
                <FieldCheckBox checkbox key="is_provider">
                    <input id="is_provider" name="is_provider" checked={is_provider} type="checkbox" value={is_provider} onChange={e => setIsProvider(e.target.checked)} />
                    <div className="fake-input"></div>
                    <label htmlFor="is_provider">Fornitore</label>
                </FieldCheckBox>
            </FormGrid>
            <FormActions>
                <Button>Modifica</Button>
            </FormActions>
        </form>
    )
}

export default CompanyEditForm
