import React, { useState, useEffect, createContext } from 'react'
import axios from 'axios'
import api from '../api.json'

import { parseUrl } from '../scripts/util'

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState({
        isLoggedIn: false,
        isReady: false,
        impersonate:  {},
        usersToImpersonate: []
    })
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        // get user auth information
        const fetchData = () => {
            const url = parseUrl(api.express.shared.user_data)
            axios.get(url, { withCredentials: true })
                .then(res => {
                    setUser({
                        ...user,
                        isLoggedIn: true,
                        id: res.data.loginId,
                        role: res.data.role,
                        terms: res.data.terms,
                        multipleUser: res.data.role === 4 && !res.data.terms ? true : false, // Must set the multipliUser condition for employee without GDPR accepted
                        impersonate: res.data.impersonate
                    })
                })
                .catch(err => {
                    console.log('App error:', err)
                    window.location.href = parseUrl(process.env.REACT_APP_LOGIN_PAGE, [process.env.REACT_APP_PROCEDURALOGIN, process.env.REACT_APP_AFTERLOGIN], false)
                })
        };

        fetchData()
    }, [])

    useEffect(() => {
        // return if not logged in or employee has not accepted terms
        if (!user.isLoggedIn || (user.role === 4 && !user.terms)) { return }

        if (user.role !== 3) {
            // if not "company" user 
            const url = parseUrl(api.express.shared.register_login)
            axios.post(url, { loginId: user.id, terms: user.terms }, { withCredentials: true })
                .then(() => {
                    axios.get(parseUrl(api.express.shared.user_info), { withCredentials: true })
                        .then(res => {
                            //Get impersonification data for current user
                            const name = res.data.name
                            user.impersonate.params[0] = currentPage  // change current paging results
                            axios.get(parseUrl(user.impersonate.url, user.impersonate.params), { withCredentials: true })
                            .then(res => {
                                setUser({
                                    ...user,
                                    isReady: true, 
                                    name: name,
                                    multipleUser: res.data.data.length ? true : false,
                                    usersToImpersonate: res.data.data,
                                    userToImpersonatePagin: res.data.meta
                                })
                            })
                        })
                })
        } else {
            const url = parseUrl(api.express.company.auth_companies);
            axios.get(url, { withCredentials: true })
                .then(res => {
                    setUser({
                        ...user,
                        companies: res.data,
                        multipleUser: true
                    })
                })
        }
    }, [user.isLoggedIn])

    const declineTerms = () => {
        const url = parseUrl(api.express.shared.register_terms)
        axios.post(url, { loginId: user.id, terms: 0 }, { withCredentials: true })
        setUser({ ...user, terms: false })
    }

    const acceptTerms = () => {
        const url = parseUrl(api.express.shared.register_terms)
        axios.post(url, { loginId: user.id, terms: 1 }, { withCredentials: true })
        .then(res => {
                setUser({ ...user, terms: true, isReady: true })
            })
    }

    const selectUserImpersonification = (id) => {
        const url = parseUrl(user.impersonate.url_to_impersonate)
            axios.post(url, { id: id }, { withCredentials: true })
                .then(() => {
                    axios.get(parseUrl(api.express.shared.user_info), { withCredentials: true })
                        .then(res => {
                            setUser({ 
                                ...user, 
                                id: id, 
                                role: res.data.role,
                                type: res.data.type,
                                isReady: true, 
                                name: res.data.name,
                                multipleUser: false,
                                usersToImpersonate: [],
                                userToImpersonatePagin: {},
                                impersonate: {}
                            })
                        })
                })
    }

    const selectCompany = (id) => {
        const url = parseUrl(api.express.shared.register_login)
            axios.post(url, { loginId: id, terms: user.terms }, { withCredentials: true })
                .then(() => {
                    axios.get(parseUrl(api.express.shared.user_info), { withCredentials: true })
                        .then(res_user_info => {
                            user.impersonate.params[0] = currentPage  // change current paging results
                            axios.get(parseUrl(user.impersonate.url, user.impersonate.params), { withCredentials: true })
                                    .then(res => {
                                        setUser({
                                            ...user,
                                            id: id, 
                                            type: res_user_info.data.type,
                                            isReady: true, 
                                            name: res_user_info.data.name,
                                            multipleUser: true,
                                            usersToImpersonate: res.data.data,
                                            userToImpersonatePagin: res.data.meta
                                        })
                                    })  
                        })
                })
    }

    const updateCurrentPage = (page) => {
        setCurrentPage(page)
        
        user.impersonate.params[0] = page  // change current paging results
        axios.get(parseUrl(user.impersonate.url, user.impersonate.params), { withCredentials: true })
                .then(res => {
                    setUser({
                        ...user,
                        usersToImpersonate: res.data.data,
                        userToImpersonatePagin: res.data.meta
                    })
                })
    }

    const impersonateSearch = str => {
        user.impersonate.params[0] = 1;
        user.impersonate.params[1] = str;
        axios.get(parseUrl(user.impersonate.url, user.impersonate.params), { withCredentials: true })
                .then(res => {
                    setUser({
                        ...user,
                        usersToImpersonate: res.data.data,
                        userToImpersonatePagin: res.data.meta
                    })
                })
    }

    const deselectUser = () => {
        setUser({ 
            ...user,
            id: null,
            isReady: false, 
            name: null
        })
    }

    const impersonateUser = () => {
        setUser({ 
            ...user
        })
    }
    
    const logout = () => {
        const url = parseUrl(api.express.shared.logout)
        axios.get(url, { withCredentials: true })
        window.location.href = parseUrl(process.env.REACT_APP_LOGIN_PAGE, [process.env.REACT_APP_PROCEDURALOGIN, process.env.REACT_APP_AFTERLOGIN], false)
    }

    const isAuthenticated = () => {
        return user.isLoggedIn
    }

    return (
        <AuthContext.Provider value={{
            user: user,
            isAuthenticated: isAuthenticated,
            selectUserImpersonification: selectUserImpersonification,
            selectCompany: selectCompany,
            declineTerms: declineTerms,
            acceptTerms: acceptTerms,
            deselectUser: deselectUser,
            impersonateUser: impersonateUser,
            logout: logout,
            updateCurrentPage: updateCurrentPage,
            impersonateSearch: impersonateSearch
        }}>
            {children}
        </AuthContext.Provider>
    )
}
