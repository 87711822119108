import React, { useState, useEffect } from 'react'
import axios from 'axios'
import styled from 'styled-components'

import api from '../../api.json'
import { mq } from '../../styled/Global'
import { Paginator, PageInfo } from '../../styled/Pagination'
import { parseUrl } from '../../scripts/util'
import { Title } from '../../components/PageTitle'
import { RequestCardForEmployees } from '../../components/cards/RequestCard'

const TableHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 120px 200px 120px;
    padding: 10px 15px;
    text-transform: uppercase;
    font-size: 14px;

    @media ${mq.mediumDown} {
        display: none;
    }

    > div:nth-child(3),
    > div:nth-child(4),
    > div:nth-child(5) {
        justify-self: center;
    }
`

const Table = styled.div`
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
`

const RequestList = ({ requests, handleDelete, handlePay }) => {
    return (
        <>
            <TableHeader>
                <div>Servizio</div>
                <div>Richiesto da</div>
                <div>Valore</div>
                <div>Stato</div>
                <div>Azioni</div>
            </TableHeader>
            <Table>
                {requests.map((el) =>
                    <RequestCardForEmployees key={el.doc_id}
                        id={el.doc_id}
                        submittedBy={`${el.employee.lastname} ${el.employee.firstname}`}
                        token={el.validation_token}
                        status={el.status_code}
                        name={el.service.name}
                        description={el.service.description}
                        created={el.created_at}
                        price={Math.round(el.transaction_value) !== 0 ? el.transaction_value : el.requested_value}
                        handleDelete={handleDelete}
                        handlePay={handlePay}
                    />
                )}
            </Table>
        </>
    )
}

const EmployeeRequest = () => {
    const [loading, setLoading] = useState(true)
    const [requests, setRequests] = useState()
    const [pagin, setPagin] = useState({})
    const [currentPage, setCurrentPage] = useState(1)

    const fetchData = () => {
        const url = parseUrl(api.express.company.employee_requests, [currentPage])
        axios.get(url, { withCredentials: true })
            .then(res => {
                // update company list
                setRequests(res.data.data);
                // update paginator data
                setPagin(res.data.meta);
                // show list
                setLoading(false);
            });
    }

    const handleDelete = id => {
        const url = parseUrl(api.express.company.delete_doc)
        axios.post(url, { id }, { withCredentials: true })
            .then(res => {
                fetchData()
            })
            .catch(err => {
                console.log('err', err)
            })
    }

    const handlePay = id => {
        const url = parseUrl(api.express.company.close_doc)
        axios.post(url, { id }, { withCredentials: true })
            .then(res => {
                fetchData()
            })
            .catch(err => {
                console.log('err', err)
            })
    }

    useEffect(() => {
        fetchData()
    }, [currentPage]);

    return (
        <>
            {!loading
                ? <>
                    <Title title="Le richieste dei tuoi dipendenti" />
                    <PageInfo tot={pagin.total} curr={pagin.current_page} last={pagin.last_page} />
                    <RequestList requests={requests} handleDelete={handleDelete} handlePay={handlePay} />
                    <Paginator pageCount={pagin.last_page} onPageChange={page => setCurrentPage(page + 1)} />
                </>
                : ''
            }
        </>
    )
}

export default EmployeeRequest;
