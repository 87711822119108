import React, { useState, useEffect } from 'react'
import axios from 'axios'
import styled from 'styled-components'

import api from '../../api.json'
import { mq } from '../../styled/Global'
import { Paginator, PageInfo } from '../../styled/Pagination'
import { parseUrl } from '../../scripts/util'
import { RequestCardForCustomers } from '../../components/cards/RequestCard'
import { Title } from '../../components/PageTitle'
import { IconSearch } from '../../styled/Icons'
import { Field } from '../../styled/Forms'
import { Button } from '../../styled/Cta'

const TableHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 120px 200px 120px;
    padding: 10px 15px;
    text-transform: uppercase;
    font-size: 14px;

    @media ${mq.mediumDown} {
        display: none;
    }

    > div:nth-child(3),
    > div:nth-child(4),
    > div:nth-child(5) {
        justify-self: center;
    }
`

const Table = styled.div`
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
`

const RequestList = ({ requests, handleAccept }) => {
    return (
        <>
            <TableHeader>
                <div>Servizio</div>
                <div>Richiesto da</div>
                <div>Valore</div>
                <div>Stato</div>
                <div>Azioni</div>
            </TableHeader>
            <Table>
                {requests.map((el) =>
                    <RequestCardForCustomers key={el.doc_id}
                        id={el.doc_id}
                        submittedBy={`${el.employee.lastname} ${el.employee.firstname}`}
                        token={el.validation_token}
                        status={el.status_code}
                        name={el.service.name}
                        description={el.service.description}
                        created={el.created_at}
                        price={Math.round(el.transaction_value) !== 0 ? el.transaction_value : el.requested_value}
                        handleAccept={handleAccept}
                    />
                )}
            </Table>
        </>
    )
}

const Search = styled.form`
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
`

const SearchInput = styled(Field)`
    flex: 1 1 0px;
    margin-right: 15px;
`
const SearchAction = styled.div`
    flex: 0 0 auto;
`

const CustomerRequest = () => {
    const [loading, setLoading] = useState(true)
    const [requests, setRequests] = useState()
    const [pagin, setPagin] = useState({})
    const [currentSearch, setCurrentSearch] = useState('')
    const [searchLoading, setSearchLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)

    const fetchData = () => {
        const url = parseUrl(api.express.company.customer_requests, [currentPage])
        axios.get(url, { withCredentials: true })
            .then(res => {
                // update company list
                setRequests(res.data.data)
                // update paginator data
                setPagin(res.data.meta)
                // show list
                setLoading(false)
            });
    }

    useEffect(() => {
        fetchData()
    }, [currentPage]);

    const handleAccept = (id, val) => {
        const url = parseUrl(api.express.company.accept_doc)
        axios.post(url, { id, val }, { withCredentials: true })
            .then(res => {
                fetchData()
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleSearch = e => {
        e.preventDefault()
        setSearchLoading(true)
        const url = parseUrl(api.express.company.check_doc)
        axios.post(url, { code: currentSearch }, { withCredentials: true })
            .then(res => {
                const arr = []
                arr.push(res.data.data)
                setRequests(arr)
                setSearchLoading(false)
            })
            .catch(err => {
                console.log(err)
                setSearchLoading(false)
            })
    }

    return (
        <>
            {!loading
                ? <>
                    <Title title="Le richieste dei tuoi clienti" />
                    <Search onSubmit={e => handleSearch(e)}>
                        <SearchInput mb="0" mr="15px">
                            <input type="text" placeholder="Cerca per codice" value={currentSearch} onChange={e => setCurrentSearch(e.target.value)} />
                        </SearchInput>
                        <SearchAction>
                            <Button loading={searchLoading} icon={IconSearch}>Cerca</Button>
                        </SearchAction>
                    </Search>
                    <PageInfo tot={pagin.total} curr={pagin.current_page} last={pagin.last_page} />
                    <RequestList requests={requests} handleAccept={handleAccept} />
                    <Paginator pageCount={pagin.last_page} onPageChange={page => setCurrentPage(page + 1)} />
                </>
                : ''
            }
        </>
    )
}

export default CustomerRequest;
