import React, { useState, useEffect } from 'react'
import axios from 'axios'

import api from '../../api.json'
import { parseUrl } from '../../scripts/util'
import { Paginator, PageInfo } from '../../styled/Pagination'
import MyRequestCard from '../../components/cards/MyRequestCard'

const Requests = () => {
    const [loading, setLoading] = useState(true)
    const [requests, setRequests] = useState()
    const [pagin, setPagin] = useState({})

    // get or update organization list
    const fetchData = url => {
        axios.get(url, { withCredentials: true })
            .then(res => {
                // update company list
                setRequests(res.data.data)
                // update paginator data
                setPagin(res.data.meta)
                // show list
                setLoading(false)
            })
    }

    useEffect(() => {
        // build url and update company list
        const url = parseUrl(api.express.employee.request_list, [1])
        axios.get(url, { withCredentials: true })
            .then(res => {
                // update company list
                setRequests(res.data.data)
                // update paginator data
                setPagin(res.data.meta)
                // show list
                setLoading(false)
            })
    }, [])

    const updatePage = page => {
        // build url and update organization list
        const url = parseUrl(api.express.employee.request_list, [page + 1])
        fetchData(url)
    }

    return (
        <>
            {!loading
                ? <>
                    <PageInfo tot={pagin.total} curr={pagin.current_page} last={pagin.last_page} />
                    {requests.map((el) =>
                        <MyRequestCard key={el.doc_id}
                            id={el.doc_id}
                            token={el.validation_token}
                            statusLabel={el.status_code} 
                            status={el.status_code} 
                            name={el.service.name}
                            description={el.service.description}
                            created={el.created_at}
                            price={Math.round(el.transaction_value) !== 0 ? el.transaction_value : el.requested_value}
                        />
                    )}
                    <Paginator pageCount={pagin.last_page} onPageChange={updatePage} />
                </>
                : ''
            }
        </>
    )
}

export default Requests
