import React, { useState, useContext } from 'react'
import styled from 'styled-components'

import { color, mq } from '../styled/Global'
import {IconLogo, IconLogout, IconChangeUser, IconMenu, IconUser, IconManual} from '../styled/Icons'
import { StyledLink } from '../styled/Cta'
import { AuthContext } from '../contexts/AuthContext'
import { navs } from '../view/routesData'
import {parseUrl} from "../scripts/util";
import api from "../api";
import axios from "axios";
import mime from "mime-types";

const StyledHeader = styled.div`
    background: linear-gradient(to right, #1e3c72, #2a5298);
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    color: #fff;
    display: grid;
    grid-template-columns: 70px 1fr 70px;
    grid-template-areas: "logo nav logout";
    justify-items: center;
    align-items: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
    
    @media ${mq.mediumDown} {
        height: 50px;
        padding-left: 15px;
        grid-template-columns: 30px 50px 1fr 70px;
        grid-template-areas: "menu logo nav logout";
    }
`

const LogoutButton = styled.button`
    background: none;
    border: 0;
`

const TriggerMenu = styled(IconMenu)`
    cursor: pointer;
`

const HeaderLeft = styled.div`
    grid-area: logo;
    display: flex;
    align-items: center;
`

const HeaderCenter = styled.div`
    grid-area: nav;
    justify-self: stretch;
    display: flex;
    justify-content: space-between;
    padding-right: 10px;

    @media ${mq.mediumDown} {
        position: fixed;
        top: 50px;
        left: -100%;
        height: calc(100vh - 50px);
        background: beige;
        width: 100%;
        background: linear-gradient(to right, #1e3c72, #2a5298);
        flex-direction: column;
        justify-content: start;
        transition: left .4s ease-in;
        padding-top: 10px;
        
        &.open {
            left: 0;
            transition: left .3s ease-out;
        }
    }
`

const Nav = styled.div`
    display: flex;

    @media ${mq.mediumDown} {
        flex-direction: column;
        order: 2;

        a {
            margin-bottom: 10px;
            padding: 10px;

            &:after {
                content: none;
            }
        }
    }
`

const HeaderRight = styled.div`
    grid-area: logout;
    align-self: stretch;
    justify-self: stretch;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${color.lightGray};
`

const UserInfo = styled.div`
    @media ${mq.mediumDown} {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        padding-left: 25px;

        > span {
            margin: 0;
            order: 2;
        }
    }
`

const UserName = styled.span`
    margin-left: 10px;
    font-size: 13px;
    margin-right: 10px;
    font-weight: 600;
    color: ${color.warning};

    @media ${mq.mediumDown} {
        margin-left: 14px;
        margin-bottom: 10px;
        padding: 10px;
        padding-left: 0;
    }
`

const ChangeUserButton = styled.div`
    display: inline-block;
    cursor: pointer;
    margin-right: 15px;
`

const GetUserManual = styled.div`
    display: inline-block;
    cursor: pointer;
    margin-right: 15px;
`

const CrmUserButton = styled.div`
    display: inline-block;
    cursor: pointer;
    margin-right: 15px;
`

const CrmUserLink = styled.a`
    text-decoration: none;
`

const HeaderLinks = ({ handleMobileMenu }) => {
    const auth = useContext(AuthContext)
    const { terms, role, type } = auth.user

    const handleMobileClick = () => {
        if (window.matchMedia(mq.mediumDown).matches) {
            handleMobileMenu()
        }
    }

    return (
        <>
            {(() => {
                switch (role) {
                    case 1:
                        return navs[role].map((el, i) =>
                            !el.hidden
                                ? <StyledLink key={i}
                                    onClick={handleMobileClick}
                                    color={color.white}
                                    ml={`15px`}
                                    exact={el.path === '/' ? true : false}
                                    activeClassName="isActive"
                                    to={el.path}>

                                    {el.name}

                                </StyledLink>
                                : ''
                        )
                    case 2:
                        return navs[role].map((el, i) =>
                            !el.hidden
                                ? < StyledLink key={i}
                                    onClick={handleMobileClick}
                                    color={color.white}
                                    ml={`15px`}
                                    exact={el.path === '/' ? true : false}
                                    activeClassName="isActive"
                                    to={el.path}>

                                    {el.name}

                                </StyledLink>
                                : ''
                        )
                    case 3:
                        return navs[role][type].map((el, i) =>
                            !el.hidden
                                ? <StyledLink key={i}
                                    onClick={handleMobileClick}
                                    color={color.white}
                                    ml={`15px`}
                                    exact={el.path === '/' ? true : false}
                                    activeClassName="isActive"
                                    to={el.path}>

                                    {el.name}

                                </StyledLink>
                                : ''
                        )
                    case 4:
                        if (terms) {
                            return navs[role].map((el, i) =>
                                !el.hidden
                                    ? <StyledLink key={i}
                                        onClick={handleMobileClick}
                                        color={color.white}
                                        ml={`15px`}
                                        exact={el.path === '/' ? true : false}
                                        activeClassName="isActive"
                                        to={el.path}>

                                        {el.name}

                                    </StyledLink>
                                    : ''
                            )
                        } else { return '' }
                    default:
                        return '';
                }
            })()}
        </>
    )
}

const Header = () => {
    const [mobileMenu, setMobileMenu] = useState(false)
    const auth = useContext(AuthContext)
    const { user, logout, deselectUser } = auth
    const { multipleUser } = user

    const handleChangeUser = () => {
        deselectUser()
        setMobileMenu(!mobileMenu)
    }

    const handleGetUserManual = () => {
        const url = parseUrl(api.express.shared.manual_download);

        axios.post(url, {}, { withCredentials: true, responseType: 'arraybuffer' })
            .then(res => {
                const type = res.headers['content-type'];
                const blob = new Blob([res.data], { type: type });
                const fileExtension = mime.extension(type);
                const URL = window.URL || window.webkitURL;
                const downloadUrl = URL.createObjectURL(blob);
                let downloadLink = document.createElement('a');

                downloadLink.target = '_blank';
                downloadLink.download = 'Manuale_istruzioni.' + fileExtension; //need to specify extension type
                // set object URL as the anchor's href
                downloadLink.href = downloadUrl;
                // append the anchor to document body
                document.body.appendChild(downloadLink);
                // fire a click event on the anchor
                downloadLink.click();
                // cleanup: remove element and revoke object URL
                document.body.removeChild(downloadLink);
                URL.revokeObjectURL(downloadUrl);
            })
            .catch(error => {
                switch (error.response.status) {
                    case 404:
                        alert("Manuale non trovato");
                        break;
                    default:
                        alert('Errore di comunicazione con il server! Contattare il supporto.');
                        break;
                }
                console.log(error);
            })
    }

    return (
        <StyledHeader multipleUser={multipleUser}>
            {window.matchMedia(mq.mediumDown).matches
                ? <TriggerMenu size="30" hex={color.white} onClick={() => setMobileMenu(!mobileMenu)} />
                : ''
            }
            <HeaderLeft >
                <IconLogo size={window.matchMedia(mq.mediumDown).matches ? 30 : 40} hex={color.white} />
            </HeaderLeft>
            <HeaderCenter className={mobileMenu ? 'open' : ''}>
                <Nav>
                    <>
                        {user.isReady
                            ? <HeaderLinks handleMobileMenu={() => setMobileMenu(!mobileMenu)} />
                            : ''
                        }
                    </>
                </Nav>
                <UserInfo>
                    {user.isReady
                        ? <CrmUserLink target="_blank" href={process.env.REACT_APP_ACCOUNT}><UserName><span>{user.name}</span></UserName><CrmUserButton><IconUser size={16} hex={color.white} /></CrmUserButton></CrmUserLink>
                        : ''
                    }
                    {user.isReady && multipleUser
                        ? <ChangeUserButton onClick={() => handleChangeUser()}>
                            <IconChangeUser size={16} hex={color.white} />
                        </ChangeUserButton>
                        : ''
                    }
                    {user.isReady
                        ? <GetUserManual onClick={() => handleGetUserManual()}>
                            <IconManual size={26} hex={color.white} />
                        </GetUserManual>
                        : ''
                    }
                </UserInfo>
            </HeaderCenter>
            <HeaderRight>
                <LogoutButton onClick={() => logout()}>
                    <IconLogout hex={color.blue} />
                </LogoutButton>
            </HeaderRight>
        </StyledHeader>
    )
}

export default Header;
