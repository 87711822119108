import React, { useState, useContext } from 'react'
import styled from 'styled-components'

import { EmployeesContext } from '../../contexts/Company.EmployeesContext'
import { color, mq } from '../../styled/Global'
import { Button } from '../../styled/Cta'
import { IconTrash, IconEdit } from '../../styled/Icons'
import { ToggleContent } from '../ToggleContent'
import { IconDown } from '../../styled/Icons'
import { Modal, ConfirmModal } from '../../styled/Modal'
import EmployeeEditForm from '../../view/company/EmployeeEditForm'

const Card = styled.div`
    border-bottom: 1px solid ${color.gray};
    position: relative;
    overflow: hidden;
    padding: 15px 0;
    display: grid;
    grid-template-areas: 
        "main quote actions"
        "info info info";
    grid-template-columns: 1fr 200px 120px;

    @media ${mq.smallOnly} {
        grid-template-columns: 1fr;
        grid-template-areas: 
            "main"
            "info"
            "quote"
            "actions";
    }

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        border-color: transparent;
    }

    .main {
        grid-area: main;
        display: flex;
        align-items: center;
    }

    .info {
        grid-area: info;
    }

    .actions {
        grid-area: actions;
        justify-self: end;
        margin-left: 20px;

        @media ${mq.smallOnly} {
            justify-self: start;
            margin-left: 36px;
            padding-top: 10px;
        }
    }
`;

const CardTitle = styled.h1`
    font-size: 16px;
    font-weight: 600;
    letter-spacing: .5px;
    color: ${color.black};
    cursor: pointer;
`

const StatusSticker = styled.div`
    display: inline-block;
    position: relative;
    padding-right: 15px;
    font-size: 14px;
    
    &:after {
        content: '';
        width: 9px;
        height: 9px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

    }
    
    &.success {
        &:after {
            background: ${color.success}
        }
    }

    &.warning {
        &:after {
            background: ${color.warning}
        }
    }

`

const Quote = styled.div`
    grid-area: quote;
    justify-self: end;
    align-self: center;
    font-size: 13px;

    @media ${mq.smallOnly} {
        justify-self: start;
        margin-left: 36px;
        margin-top: 15px;
    }

    .value {
        display: inline-block;
        border-radius: 25px;
        padding: 4px 8px;
        font-weight: 600;
        color: ${color.white};
        background: ${color.blue};
        margin-right: 10px;
    }
`

const MoreInfo = styled.p`
    width: 100%;
    font-size: 13px;
    padding-top: 10px;
    padding-left: 35px;
    display: block;
    color: ${color.darkGray};
    line-height: 1.8;

    .value {
        color: ${color.black};
    }
`

const ToggleTrigger = styled.button`
    background: none;
    border: none;
    transition: all .4s ease;

    &.isActive {
        transform: rotate(180deg);
    }
`

const Actions = styled.div`
    padding-left: 35px;
    margin-top: 10px;

    @media ${mq.smallOnly} {
        button {
            margin-bottom: 15px;
        }
    }
`

function EmployeeCard(props) {
    const employeesContext = useContext(EmployeesContext)
    const { remove } = employeesContext

    const [moreInfoState, setMoreInfoState] = useState(false)
    const [deleteModalState, setDeleteModalState] = useState()
    const [editModalState, setEditModalState] = useState()

    const currentQuote = props.quotes[0];

    return (
        <Card>
            <div className="main">
                <ToggleTrigger className={moreInfoState ? 'isActive' : ''} onClick={() => setMoreInfoState(!moreInfoState)}>
                    <IconDown />
                </ToggleTrigger>
                <CardTitle onClick={() => setMoreInfoState(!moreInfoState)}>{props.name}</CardTitle>
            </div>
            <Quote>
                <span className="value">{currentQuote.threshold - currentQuote.value} €</span>
                <span className="threshold">di {currentQuote.threshold} €</span>
            </Quote>
            <div className="info">
                <ToggleContent isVisible={moreInfoState}>
                    <MoreInfo>
                        {props.taxcode
                            ? <>
                                C.F: <span className="value">{props.taxcode}</span><br />
                            </>
                            : ''
                        }
                        {/* {props.address && props.address.website
                            ? <>
                                Website: <span className="value">{props.address.website}</span><br />
                            </>
                            : ''
                        } */}
                        {props.email
                            ? <>
                                Email: <span className="value">{props.email}</span><br />
                            </>
                            : ''
                        }
                        {props.address && props.address.phone
                            ? <>
                                Telefono: <span className="value">{props.address.phone}</span><br />
                            </>
                            : ''
                        }
                        {/* {props.address && props.address.fax
                            ? <>
                                Fax: <span className="value">{props.address.fax}</span><br />
                            </>
                            : ''
                        } */}
                        {props.address && (props.address.city || props.address.province || props.address.street || props.address.street_number || props.address.postcode)
                            ? <>
                                Indirizzo: <span className="value">{props.address.city}, {props.address.postcode} {props.address.province} | {props.address.street} - {props.address.street_number}</span>
                            </>
                            : ''
                        }
                    </MoreInfo>
                    <Actions>
                        <Button mr="15px" icon={IconTrash} onClick={() => setDeleteModalState(!deleteModalState)}>Cancella</Button>
                        <ConfirmModal
                            msg={`Sei sicuro di voler eliminare ${props.name}?`}
                            isOpen={deleteModalState}
                            onBackgroundClick={() => setDeleteModalState(!deleteModalState)}
                            onEscapeKeydown={() => setDeleteModalState(!deleteModalState)}
                            onConfirm={() => remove(props.id)}
                            onCancel={() => setDeleteModalState(!deleteModalState)} />
                        <Button icon={IconEdit} onClick={() => setEditModalState(!editModalState)}>Modifica</Button>
                        <Modal
                            title="Aggiorna informazioni"
                            close={() => setEditModalState(!editModalState)}
                            isOpen={editModalState}>
                            <EmployeeEditForm
                                id={props.id}
                                city={props.address.city}
                                street={props.address.street}
                                streetNumber={props.address.street_number}
                                postcode={props.address.postcode}
                                province={props.address.province}
                                phone={props.address.phone}
                                fax={props.address.fax}
                                website={props.address.website}
                                firstname={props.firstname}
                                lastname={props.lastname}
                                email={props.email}
                                taxCode={props.taxcode}
                                threshold={currentQuote.threshold}
                                quotes={props.quotes}
                                is_admin={props.is_admin}
                            />
                        </Modal>
                    </Actions>
                </ToggleContent>
            </div>
            <div className="actions">
                {!props.registered || !props.invited
                    ? <Button icon={props.buttonIcon} onClick={props.buttonAction}>{props.buttonText}</Button>
                    : <StatusSticker className="success">Invitato</StatusSticker>
                }
            </div>
        </Card>
    );
}

export default EmployeeCard;
