import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'

import { MyServicesContext } from '../../contexts/Company.MyServicesContext'
import { Modal } from '../../styled/Modal'
import { color } from '../../styled/Global'
import { Button } from '../../styled/Cta'
import { IconTrash, IconEdit } from '../../styled/Icons'
import { ConfirmModal } from '../../styled/Modal'
import { Field, Legend } from '../../styled/Forms'

const StyledCard = styled.div`
    border-bottom: 1px solid ${color.gray};
    z-index: 1;
    position: relative;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    background: ${color.white};
    padding: 20px;
    line-height: 1.4;

    &:last-child {
        border-bottom: 0;
    }
`;

const ServiceName = styled.h1`
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
    color: ${color.blue};
`

const CategoryName = styled.p`
    font-size: 14px;
    color: ${color.darkGray};
    margin-bottom: 10px;
    font-weight: 600;
`

const Description = styled.p`
    font-size: 14px;
    color: ${color.darkGray};
    margin-bottom: 15px;
`

const Address = styled.p`
    font-size: 14px;
    color: ${color.darkGray};
    margin-bottom: 15px;
`

const Actions = styled.div`
    text-align: right;
    margin-top: 10px;
`

const FormGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin: 0 auto 30px auto;
    max-width: 900px;
`

const FormActions = styled.div`
    text-align: center;

    button {
        margin: 0 10px;
    }
`

const Radios = styled.div`
    column-count: 2;
    column-gap: 20px;
`

const TextAreaFixed = styled.textarea`
    resize: none;
`

const MyServiceCard = (props) => {
    const myServicesContext = useContext(MyServicesContext)
    const { categories, edit, remove } = myServicesContext
    const [editModalState, setEditModalState] = useState()
    const [deleteModalState, setDeleteModalState] = useState()

    const [name, setName] = useState(props.name || '')
    const [desc, setDesc] = useState(props.desc || '')
    const [address, setAddress] = useState(props.address || '')
    const [currentCat, setCurrentCat] = useState()

    useEffect(() => {
        if (props.cat[0]) {
            setCurrentCat(props.cat[0].category_id)
        }
    }, [])

    const handleSubmit = e => {
        e.preventDefault()
        if (!name || !desc || !address || !currentCat) { return }
        edit(props.id, { name, description: desc, address: address, category_id: currentCat })
        setEditModalState(!editModalState)
    }

    return (
        <StyledCard>
            <div>
                <ServiceName>{props.name}</ServiceName>
                {props.cat[0]
                    ? <CategoryName>{props.cat[0].name}</CategoryName>
                    : ''
                }
                <Description>{props.desc}</Description>
                <Address>{props.address}</Address>
            </div>
            <Actions>
                <Button icon={IconTrash} onClick={() => setDeleteModalState(!deleteModalState)}>Cancella</Button>
                <ConfirmModal
                    msg={`Sei sicuro di voler eliminare ${props.name}?`}
                    isOpen={deleteModalState}
                    onBackgroundClick={() => setDeleteModalState(!deleteModalState)}
                    onEscapeKeydown={() => setDeleteModalState(!deleteModalState)}
                    onConfirm={() => remove(props.id)}
                    onCancel={() => setDeleteModalState(!deleteModalState)} />

                <Button ml="15px" icon={IconEdit} onClick={() => setEditModalState(!editModalState)}>Modifica</Button>
                <Modal
                    title="Modifica servizio"
                    isOpen={editModalState}
                    close={() => setEditModalState(!editModalState)}
                >
                    <form onSubmit={e => handleSubmit(e)}>
                        <FormGrid>
                            <Field>
                                <label>Nome</label>
                                <input required type="text" value={name} onChange={e => setName(e.target.value)} />
                            </Field>
                            <Field>
                                <label>Descrizione</label>
                                <TextAreaFixed rows="6" required type="text" value={desc} onChange={e => setDesc(e.target.value)}/>
                            </Field>
                            <Field>
                                <label>Indirizzo</label>
                                <input required type="text" value={address} onChange={e => setAddress(e.target.value)} />
                            </Field>
                            <Legend>Categorie</Legend>
                            <Radios>
                                {categories.map(cat =>
                                    <Field radio key={cat.category_id}>
                                        <input name="category" id={cat.category_id} type="radio" value={cat.category_id} onChange={e => setCurrentCat(e.target.value * 1)} checked={currentCat === cat.category_id ? true : false} />
                                        <div className="fake-input"></div>
                                        <label htmlFor={cat.category_id}>{cat.name}</label>
                                    </Field>
                                )}
                            </Radios>
                        </FormGrid>
                        <FormActions>
                            <Button icon={IconEdit}>Modifica</Button>
                        </FormActions>
                    </form>
                </Modal>
            </Actions>
        </StyledCard>
    );
}

export default MyServiceCard
