import React, { useState, useEffect, createContext } from 'react'
import axios from 'axios'
import api from '../api.json'
import { parseUrl } from '../scripts/util'

export const EmployeesContext = createContext()

export const EmployeesProvider = ({ children }) => {
    const [loading, setLoading] = useState(true)
    const [employees, setEmployees] = useState()
    const [pagin, setPagin] = useState({})
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        // build url and update company list
        updateList();
    }, [currentPage])
    
    // get or update employees list
    const updateList = () => {
        const url = parseUrl(api.express.company.employee_list, [currentPage, '']);
        axios.get(url, { withCredentials: true })
            .then(res => {
                // console.log(res.data.data)
                // update company list
                setEmployees(res.data.data)
                // update paginator data
                setPagin(res.data.meta)
                // show list
                setLoading(false)
            })
    }

    const remove = id => {
        const url = parseUrl(api.express.company.employee_remove)
        axios.post(url, { id }, { withCredentials: true })
            .then(() => {
                updateList()
            })
            .catch(err => {
                console.log('err', err)
            })
    }

    return (
        <EmployeesContext.Provider value={{
            loading,
            employees,
            pagin,
            remove,
            updateList,
            setCurrentPage
        }}>
            {children}
        </EmployeesContext.Provider>
    )
}
