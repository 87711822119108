import styled from 'styled-components';
import { color, mq } from './Global';

const styleCheck = (top, left, width, height) => {
    return `
        position: absolute;
        top: ${top}px;
        left: ${left}px;
        width: ${width}px;
        height: ${height}px;
    `
}

export const Legend = styled.span`
    display: block;
    font-size: 14px;
    text-transform: uppercase;
    color: ${color.blue};
`

export const FormGrid = styled.div`
    display: grid;
    ${props => props.tcolumns ? `
        grid-template-columns: ${props.tcolumns};`
        : ``}
    grid-gap: ${props => props.gap ? props.gap : `20px`};
    margin-bottom: 30px;

    @media ${mq.smallOnly} {
        display: block;

        & > div:not(:last-child) {
            margin-bottom: 20px;
        }
    }
`

export const Field = styled.div`
    label {
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin-bottom: 7px;
        padding-left: 6px;
        display: block;
    }

    input[type="text"],
    input[type="number"],
    input[type="password"],
    input[type="email"],
    input[type="tel"],
    input[type="number"],
    textarea {
        padding: 8px 15px;
        font-size: 14px;
        letter-spacing: 1px;
        display: block;
        width: 100%;
        border-radius: 25px;
        border: 1px solid ${color.gray};
    }

    ${props => props.checkbox ? `
        position: relative;
        padding-left: 20px;
        margin-bottom: 10px;

        label {
            margin-bottom: 0;
            font-size: 12px;
            line-height: 1.9;
        }

        input[type="checkbox"] {
            opacity: 0;
            margin: 0;
            z-index: 1;

            ${styleCheck(0, 0, 19, 19)}
        }

        input[type="checkbox"] + .fake-input {
            &::before {
                content: "";
                background: transparent;
                border: 2px solid ${color.blue};
                border-radius: 3px;

                ${styleCheck(2, 0, 19, 19)}
            }

            &:after {
                content: "";
                background: ${color.blue};
                background-repeat: no-repeat;
                background-position: center;
                background-size: 95%;
                opacity: 0;
                border-radius: 2px;
                transition: opacity .3s ease;

                ${styleCheck(7, 5, 9, 9)}
            }
        }

        input[type="checkbox"]:checked + .fake-input {
            &:after {
                content: "";
                opacity: 1;
            }
        }
    ` : ''}

    ${props => props.radio ? `
        position: relative;
        margin-bottom: 20px;
        min-height: 20px;
        line-height: 20px;

        label {
            margin-bottom: 0;
            padding-left: 28px;
        }

        input[type="radio"] {
            opacity: 0;
            margin: 0;
            z-index: 1;

            ${styleCheck(0, 0, 19, 19)}
        }

        input[type="radio"] + .fake-input {
            &::before {
                content: "";
                background: transparent;
                border: 1px solid ${color.darkGray};
                border-radius: 50%;

                ${styleCheck(2, 0, 19, 19)}
            }

            &::after {
                content: "";
                background: ${color.blue};
                border-radius: 50%;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 95%;
                opacity: 0;
                transition: opacity .3s ease;

                ${styleCheck(7, 5, 9, 9)}
            }
        }

        input[type="radio"]:checked + .fake-input {
            &::after {
                content: "";
                opacity: 1;
            }
        }
    ` : ''}
`;
