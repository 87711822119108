import { createGlobalStyle } from 'styled-components'

const bp = {
    small: 0,
    medium: 640,
    large: 1024,
    xlarge: 1200
}

export const mq = {
    smallOnly: `(max-width: ${bp.medium - 1}px)`,
    medium: `(min-width: ${bp.medium}px)`,
    mediumDown: `(max-width: ${bp.large - 1}px)`,
    mediumOnly: `(min-width: ${bp.medium}px) and (max-width: ${bp.large - 1}px)`,
    large: `(min-width: ${bp.large}px)`,
    largeDown: `(max-width: ${bp.xlarge - 1}px)`,
    largeOnly: `(min-width: ${bp.large}px) and (max-width: ${bp.xlarge - 1}px)`,
    xlarge: `(min-width: ${bp.xlarge}px)`
};

export const color = {
    black: '#000000',
    white: '#FFFFFF',
    blue: '#2a5298',
    darkGray: '#5E5E5E',
    gray: '#BCBCBC',
    lightGray: '#f5f7fa',
    success: '#449D44',
    error: '#D9534F',
    warning: '#F0AD4E'
}

export const shadow = {
    default: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    strong: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'
}

export const ResetStyles = createGlobalStyle`
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }

    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
        display: block;
    }

    body {
        line-height: 1;
        letter-spacing: 1px;
        font-family: 'Open Sans', sans-serif;
        color: #000000;
    }

    ol, ul {
        list-style: none;
    }

    blockquote, q {
        quotes: none;
    }

    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    img {
        max-width: 100%;
        vertical-align: middle;
    }

    p {
        line-height: 1.6;
    }

    * {
        box-sizing: border-box;
    }

    *:before,
    *:after {
        box-sizing: inherit;
    }

    strong {
        font-weight: 600;
    }

    button {
        cursor: pointer;
    }
`
