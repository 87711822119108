import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { AuthContext } from '../../contexts/AuthContext'
import { Button } from '../../styled/Cta'
import { Field } from '../../styled/Forms'

const StyledCard = styled.div`
    h1 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 30px;
    }

    p {
        margin-bottom: 15px;
        font-size: 14px;
    }
`

const LinkAnchor = styled.a`
    font-size: 14px;
    font-weight: 600;
`

const FieldCheckBox = styled(Field)`
    margin-top: 24px;
    margin-bottom: 24px;
`

const AcceptTermsButton = styled(Button)`
    :disabled{
        opacity: 20%;
    }
`


const GdprCard = () => {
    const auth = useContext(AuthContext)
    const { declineTerms, acceptTerms } = auth
    const [validatePrivacy, setValidatePrivacy] = useState(false)

    return (
        <StyledCard>
            <div>
                <h1>Privacy</h1>
                <p>Clicando al seguente link "Informativa Privacy" potrete accedere al modello che illustra la generale politica della ns. Società in ordine al trattamento dei dati personali svolto su o tramite il sito medesimo.</p>
                
                <LinkAnchor href="https://sso.qhub.it/account/privacy" target="blank">Informativa Privacy</LinkAnchor>

                <FieldCheckBox checkbox key="privacy-checkbox">
                    <input id="privacy-checkbox" name="privacy-checkbox" checked={validatePrivacy} type="checkbox" value={validatePrivacy} onChange={e => setValidatePrivacy(e.target.checked)} />
                    <div className="fake-input"></div>
                    <label htmlFor="privacy-checkbox">Ho preso visione dell'informativa sulla privacy al link "Informativa Privacy"</label>
                </FieldCheckBox>
            </div>
            <div>
                <AcceptTermsButton mr="10px" onClick={acceptTerms} disabled={!validatePrivacy}>Accept</AcceptTermsButton>
                <Button onClick={declineTerms}>Decline</Button>
            </div>
        </StyledCard>
    );
}

export default GdprCard;
