import React from 'react'
import styled from 'styled-components'

import { color } from '../styled/Global'

const NotFoundWrap = styled.div`
    height: 300px;
    text-align: center;

    h1 {
        font-size: 260px;
        color: ${color.white};
        font-weight: 600;
        margin-bottom: 20px;
    }

    span {
        color: ${color.gray};
        font-size: 18px;
    }
`

const NotFound = () => {
    return (
        <NotFoundWrap>
            <h1>404</h1>
            <span>Pagina non trovata</span>
        </NotFoundWrap>
    )
}

export default NotFound
