import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Modal, ConfirmModal } from '../../styled/Modal'
import { color, mq } from '../../styled/Global'
import { Button } from '../../styled/Cta'
import { Field } from 'styled/Forms'
import { Callout as BaseCallout } from '../../styled/Callout'

const Callout = styled(BaseCallout)`
    margin-bottom: 30px;
`

const StyledCard = styled.div`
    padding: 0;
    background: ${color.white};
    display: grid;
    grid-template-columns: 1fr 1fr 120px 200px 120px;
    align-items: center;
    padding: 20px 15px;
    line-height: 1.4;
    
    @media ${mq.mediumOnly} {
        grid-template-columns: 1fr 1fr;
    }

    @media ${mq.smallOnly} {
        grid-template-columns: 1fr;
    }

    .label-for-small {
        display: none;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-size: 14px;

        @media ${mq.mediumDown} {
            display: block;
        }
    }

    &:nth-child(even) {
        background: #e5e7ea;
    }

    .service-name {
        font-weight: 600;
        color: ${color.blue}
    }

    .service-description,
    .service-id,
    .token {
        display: block;
        font-size: 13px;
        color: ${color.darkGray}
    }

    .submitter-name {
        display: block;
    }

    .request-date {
        display: block;
        font-size: 13px;
        color: ${color.darkGray}
    }

    .title {
        @media ${mq.smallOnly} {
            padding-bottom: 10px;
            margin-bottom: 10px;
        }
    }

    .submitter {
        @media ${mq.smallOnly} {
            padding-bottom: 10px;
        }
    }

    .quote,
    .status,
    .action {
        justify-self: center;

        @media ${mq.mediumDown} {
            margin-top: 15px;
            justify-self: self-start;
        }
    }

    .action {
        text-align: center;

        button {
            @media ${mq.mediumDown} {
                margin-right: 10px;
            }
        }
    }
`;

const StatusTicket = styled.span`
    position: relative;
    display: inline-block;
    padding: 3px 12px;
    font-size: 12px;
    letter-spacing: 1px;
    border-radius: 15px;
    color: ${color.white};

    &.error {
        background: ${color.error};
    }

    &.success {
        background: ${color.success};
    }

    &.pending {
        background: ${color.warning};
    }

    &.closed {
        background: ${color.error};
    }

    &.clean {
        color: ${color.black};
    }
`

const Quote = styled.div`
    font-size: 13px;
    display: inline-block;
    border-radius: 25px;
    padding: 4px 10px;
    font-weight: 600;
    color: ${color.white};
    background: ${color.blue};
`

export const RequestCardForEmployees = ({ id, ...props }) => {
    const [acceptedModal, setAcceptedModal] = useState(false)
    const [deleteModalState, setDeleteModalState] = useState()

    const statusLabels = {
        closed: {
            label: 'pagato',
            class: 'success'
        },
        accepted: {
            label: 'da pagare',
            class: 'pending'
        },
        pending: {
            label: 'in attesa',
            class: 'clean'
        },
        cancelled: {
            label: 'cancellato',
            class: 'error'
        }
    };

    return (
        <StyledCard>
            <div className="title">
                <span className="label-for-small">Servizio</span>
                <h1 className="service-name">{props.name}</h1>
                <span className="service-description">{props.description}</span>
                <span className="token">Codice: {props.token}</span>
                {/*<span className="service-id">ID: {id}</span>*/}
            </div>
            <div className="submitter">
                <span className="label-for-small">Richiesto da</span>
                <span className="submitter-name">{props.submittedBy}</span>
                <span className="request-date">il {props.created}</span>
            </div>
            <div className="quote">
                <Quote>{props.price} €</Quote>
            </div>
            <div className="status">
                <StatusTicket className={statusLabels[props.status].class}>{statusLabels[props.status].label}</StatusTicket>
            </div>
            <div className="action">
                {props.status === 'pending'
                    ? <>
                        <Button onClick={() => setDeleteModalState(!deleteModalState)}>Cancella</Button>
                        <ConfirmModal
                            msg={`Sei sicuro di voler eliminare ${props.token}?`}
                            isOpen={deleteModalState}
                            onBackgroundClick={() => setDeleteModalState(!deleteModalState)}
                            onEscapeKeydown={() => setDeleteModalState(!deleteModalState)}
                            onConfirm={() => props.handleDelete(id)}
                            onCancel={() => setDeleteModalState(!deleteModalState)} />
                    </>
                    : ''
                }
                {props.status === 'accepted'
                    ? <>
                        <Button mb="15px" onClick={() => setAcceptedModal(!acceptedModal)}>Paga</Button>
                        <ConfirmModal
                            msg={`Sei sicuro di voler pagare ${props.token}?`}
                            isOpen={acceptedModal}
                            onBackgroundClick={() => setAcceptedModal(!acceptedModal)}
                            onEscapeKeydown={() => setAcceptedModal(!acceptedModal)}
                            onConfirm={() => props.handlePay(id)}
                            onCancel={() => setAcceptedModal(!acceptedModal)} />

                        <Button onClick={() => setDeleteModalState(!deleteModalState)}>Cancella</Button>
                        <ConfirmModal
                            msg={`Sei sicuro di voler eliminare ${props.token}?`}
                            isOpen={deleteModalState}
                            onBackgroundClick={() => setDeleteModalState(!deleteModalState)}
                            onEscapeKeydown={() => setDeleteModalState(!deleteModalState)}
                            onConfirm={() => props.handleDelete(id)}
                            onCancel={() => setDeleteModalState(!deleteModalState)} />
                    </>
                    : ''
                }
            </div>
        </StyledCard>
    );
}

export const RequestCardForCustomers = props => {
    const [pendingModal, setPendingModal] = useState(false)
    const [transactionValue, setTransactionValue] = useState(props.price)
    const [errorMsg, setErrorMsg] = useState(false)
    const [warningMsg, setWarningMsg] = useState(false)
    const [showWarning, setShowWarning] = useState(false)

    const statusLabels = {
        closed: {
            label: 'pagato',
            class: 'success'
        },
        accepted: {
            label: 'accettato',
            class: 'pending'
        },
        pending: {
            label: 'in attesa',
            class: 'clean'
        },
        cancelled: {
            label: 'cancellato',
            class: 'error'
        }
    }

    const onAccept = e => {
        e.preventDefault()

        if ( parseFloat(transactionValue) > parseFloat(props.price) ) {
            setErrorMsg("Importo richiesto superiore alla disponibilità assegnata al servizio!")
            setWarningMsg(false)
            return
        }

        if ( (parseFloat(transactionValue) < parseFloat(props.price)) && !showWarning ){
            setErrorMsg(false)
            setWarningMsg("Confermi nuovo importo?")
            setShowWarning(true)
            return
        }

        props.handleAccept(props.id, transactionValue)
    }

    // Preserve the correct price on open/close modal
    useEffect(() => {
        setErrorMsg(false)
        setWarningMsg(false)
        setShowWarning(false)
        setTransactionValue(props.price)
    }, [pendingModal])

    return (
        <StyledCard>
            <div className="title">
                <span className="label-for-small">Servizio</span>
                <h1 className="service-name">{props.name}</h1>
                <span className="service-description">{props.description}</span>
                <span className="token">Codice: {props.token}</span>
                {/*<span className="service-id">ID: {props.id}</span>*/}
            </div>
            <div className="submitter">
                <span className="label-for-small">Richiesto da</span>
                <span className="submitter-name">{props.submittedBy}</span>
                <span className="request-date">il {props.created}</span>
            </div>
            <div className="quote">
                <Quote>{props.price} €</Quote>
            </div>
            <div className="status">
                <StatusTicket className={statusLabels[props.status].class}>{statusLabels[props.status].label}</StatusTicket>
            </div>
            <div className="action">
                {props.status === 'pending'
                    ? <>
                        <Button onClick={() => setPendingModal(!pendingModal)}>Accetta</Button>
                        <Modal
                            title={`Accetta ${props.token}`}
                            isOpen={pendingModal}
                            close={() => setPendingModal(!pendingModal)}
                        >
                            <form onSubmit={e => onAccept(e)}>
                                {errorMsg
                                    ? <Callout status="error" title={errorMsg} />
                                    : ''
                                }
                                {warningMsg
                                    ? <Callout status="warning" title={warningMsg} />
                                    : ''
                                }
                                <Field>
                                    <label>Aggiorna valore</label>
                                    <input type="number" value={transactionValue} onChange={(e) => setTransactionValue(e.target.value)} />
                                </Field>
                                <div style={{textAlign: 'center'}}>
                                    <Button mt="10px">Conferma</Button>
                                    <Button mt="10px" ml="20px" onClick={() => setPendingModal(!pendingModal) }>Annulla</Button>
                                </div>
                            </form>
                        </Modal>
                    </>
                    : ''
                }
            </div>
        </StyledCard>
    );
}
