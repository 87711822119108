require("dotenv").config()

export const parseUrl = (url, params = false, base = true) => {
    let res = url
    if (params && Array.isArray(params)) {
        params.forEach((el, i) => {
            const find = `{${i}}`
            res = res.replace(find, el)
        })
    }
    return base ? process.env.REACT_APP_BASE_EXPRESS_URL + res : res
}

export const appendToUrl = (url, obj, start = true) => {
    let res = start ? url + '?' : url
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            res += `${key}=${encodeURI(obj[key])}&`
        }
    }
    return res
}

export const getElementHeight = ref => ref.current ? ref.current.getBoundingClientRect().height : 0

export const removeDuplicates = (array, key) => {
    let lookup = {};
    let result = [];
    for ( let i = 0; i < array.length; i++ ) {
        if(!lookup[array[i][key]]){
            lookup[array[i][key]] = true;
            result.push(array[i]);
        }
    }
    return result;
}
