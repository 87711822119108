import React, { useState, useContext, useEffect } from 'react'
import FormData from 'form-data'
import { Button } from '../../styled/Cta'
import styled from "styled-components"
import InputFiles from "react-input-files"
import { color } from "../../styled/Global"
import { Field, Legend } from "../../styled/Forms"
import { EmployeeContext } from "../../contexts/EmployeeContext"
import { IconPlus } from "../../styled/Icons"
import { Callout as BaseCallout } from "../../styled/Callout"

const CustomRequests = styled.div`
    text-align: center;
    margin-bottom: 30px;
    margin-top: 30px;
`;

const CustomRequestsCard = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
        font-weight: 600;
        margin-bottom: 0;
    }
`;


const FormGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin: 0 auto 30px auto;
    max-width: 900px;
`

const Radios = styled.div``

const FormActions = styled.div`
    text-align: center;

    button {
        margin: 0 10px;
    }
`

const Callout = styled(BaseCallout)`
    margin-bottom: 30px;
`

const FileName = styled.span`
    font-size: 14px;
    padding-left: 15px;
`

const FieldCheckBox = styled(Field)`
    margin-top: 24px;
    margin-bottom: 24px;
    text-align: left;
`

const ImportButton = styled(Button)`
    :disabled{
        opacity: 20%;
    }
`

const ExpenseReportImport = props => {
    const employeeContext = useContext(EmployeeContext);
    const { employee, updateQuotes } = employeeContext;
    const [errorMsg, setErrorMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [inputFile, setInputFile] = useState('');
    const [value, setValue] = useState('');
    const [description, setDescription] = useState('');
    const [quote, setQuote] = useState('');
    const [validateImport, setValidateImport] = useState(false)

    useEffect(() => {
        updateQuotes()
    }, [])

    const resetMsg = () => {
        setErrorMsg(false);
        setSuccessMsg(false);
    };

    const checkFileExtension = (file) => {
        const validFileExtensions = [".gif",".jpg",".jpe",".tiff",".tif",".jpeg",".bmp",".png",".pdf"];
        if (file != null) {
            return (new RegExp('(' + validFileExtensions.join('|').replace(/\./g, '\\.') + ')$')).test(file.name);
        }
        else {
            return false;
        }
    };

    //custom form validation
    const validate = (inputFile) => {
        let errors = [];
        let valid = true;

        if (inputFile != null) {
            if (checkFileExtension(inputFile) === false) {
                errors.push("File non valido");
                errors.push("Estensione non riconosciuta");
                valid = false;
            }
        }
        else {
            errors.push("Nessun file caricato");
            valid = false
        }

        if (valid === false) {
            let errString = "Sono stati riscontrati degli errori: ";
            errString += errors.toString();
            setErrorMsg(errString);
        }
        return valid;
    };

    //upload file into form
    const uploadFile = (files) => {
        let file = files.item(0);
        setInputFile(file);
        resetMsg();

        if (checkFileExtension(file) === false) {
            setErrorMsg("Sono stati riscontrati degli errori: File non valido, estensione non riconosciuta");
        }
        else {
            document.getElementById("filename").textContent = file.name;
        }
    };

    // import data
    const importExpenseReport = e => {
        e.preventDefault();
        const formData = new FormData();
        const file = inputFile;
        formData.append('file', file);
        resetMsg();

        if (validate(file) === true) {
            props.handleImport(setSuccessMsg, setErrorMsg, formData, value, quote, description);
        }
    };

    // TODO: download PDF instructions
    const downloadInstructions = e => {
        e.preventDefault()
        resetMsg();
        props.handleDownloadInstructions(setErrorMsg)
    };

    return (
        <div>
            {errorMsg
                ? <Callout status="error" title={errorMsg} />
                : ''
            }
            {successMsg
                ? <Callout status="success" title={successMsg} />
                : ''
            }
            <CustomRequests>
                <CustomRequestsCard>
                    <InputFiles accept="application/pdf, image/*" multiple={false} onChange={files => uploadFile(files)}>
                        <Button color={color.warning}>Carica Report</Button>
                        <FileName id="filename"></FileName>
                    </InputFiles>
                    <Button color={color.warning} onClick={(e) => downloadInstructions(e)}>Istruzioni servizi a rimborso</Button>
                </CustomRequestsCard>
                <FieldCheckBox checkbox key="instructions-checkbox">
                    <input id="instructions-checkbox" name="instructions-checkbox" checked={validateImport} type="checkbox" value={validateImport} onChange={e => setValidateImport(e.target.checked)} />
                    <div className="fake-input"></div>
                    <label htmlFor="instructions-checkbox">Ho preso visione del documento "istruzioni servizi a rimborso"</label>
                </FieldCheckBox>
            </CustomRequests>
            <form onSubmit={e => importExpenseReport(e)}>
                <FormGrid>
                    <Legend>Saldi</Legend>
                    {employee.quotes
                        ? <Radios>
                            {employee.quotes.map((q, i) =>
                                (q.threshold - q.value) > 0
                                ? <Field radio key={i}>
                                    <input name="category" required={i === 0 ? true : false} id={q.welfare_quote_id} type="radio" value={q.welfare_quote_id} onChange={e => setQuote(e.target.value)} />
                                    <div className="fake-input"></div>
                                    <label htmlFor={q.welfare_quote_id}>{`${q.supplier.name} (${q.year})`} - Welfare disponibile {q.threshold - q.value}€</label>
                                  </Field>
                                : ''
                            )}
                        </Radios>
                        : ''
                    }
                    <Field>
                        <label>Valore (€)</label>
                        <input required type="number" value={value} onChange={e => setValue(e.target.value)} />
                    </Field>
                    <Field>
                        <label>Breve descrizione</label>
                        <input required type="text" value={description} onChange={e => setDescription(e.target.value)} />
                    </Field>
                </FormGrid>
                <FormActions>
                    <ImportButton disabled={!validateImport} icon={IconPlus}>Richiedi</ImportButton>
                </FormActions>
            </form>
        </div>
    )
};

export default ExpenseReportImport;
