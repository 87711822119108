import React from 'react'
import { Icon } from 'react-icons-kit'
import { pocket } from 'react-icons-kit/feather/pocket'
import { logOut } from 'react-icons-kit/feather/logOut'
import { chevronDown } from 'react-icons-kit/feather/chevronDown'
import { spinner9 } from 'react-icons-kit/icomoon/spinner9'
import { alertOctagon } from 'react-icons-kit/feather/alertOctagon'
import { x } from 'react-icons-kit/feather/x'
import { filter } from 'react-icons-kit/feather/filter'
import { trash2 } from 'react-icons-kit/feather/trash2'
import { edit2 } from 'react-icons-kit/feather/edit2'
import { plus } from 'react-icons-kit/feather/plus'
import { check } from 'react-icons-kit/feather/check'
import { search } from 'react-icons-kit/feather/search'
import { euro } from 'react-icons-kit/metrize/euro'
import { listCircle } from 'react-icons-kit/metrize/listCircle'
// import {uploadSelectionCircle} from 'react-icons-kit/metrize/uploadSelectionCircle'
import {atom} from 'react-icons-kit/metrize/atom'
import {arrowDownBig} from 'react-icons-kit/metrize/arrowDownBig'
import {user} from 'react-icons-kit/metrize/user'
import {menu} from 'react-icons-kit/feather/menu'
import { box } from 'react-icons-kit/feather/box'
import {manuals} from './manuals'

import { color } from './Global'

export const IconLogo = ({ hex, size }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.13558 27.111C2.13558 40.9018 13.3202 52.0865 27.111 52.0865C40.9018 52.0865 52.0865 40.9018 52.0865 27.111C52.0865 13.3202 40.9018 2.13558 27.111 2.13558C13.3202 2.13558 2.13558 13.3202 2.13558 27.111ZM0 27.111C0 12.1258 12.1258 0 27.111 0C42.0963 0 54.2221 12.1258 54.2221 27.111C54.2221 42.0963 42.0963 54.2221 27.111 54.2221C12.1258 54.2221 0 42.0963 0 27.111Z" fill={hex} />
            <path d="M34.0608 22.2246C31.4908 20.813 28.7761 20.27 25.808 20.27C18.0258 20.27 12.3068 24.505 12.3068 31.8528C12.3068 38.4768 16.9399 43.6166 25.6632 43.6166C28.5227 43.6166 31.3098 42.8203 33.6264 41.2639L33.8798 39.0921L33.554 38.9473C31.3822 40.7571 29.3914 42.024 26.3509 42.024C20.6681 42.024 16.8675 37.7166 16.8675 31.6357C16.8675 26.6406 19.7994 21.8989 25.8442 21.8989C27.6178 21.8989 29.7896 22.5504 31.0203 23.7811C31.5994 24.2154 31.9976 24.8308 32.4319 25.3375H32.8663L34.0608 22.2246Z" fill={hex} />
            <path d="M36.8116 43.6164H40.9742C40.9742 43.6164 42.5668 32.649 41.4809 22.2606C40.395 11.6189 32.5766 10.6416 28.3055 10.6416C23.5275 10.6416 18.4601 13.4649 15.2748 15.7453V20.1974H16.1797C22.4055 12.8858 36.8478 6.40664 36.8478 21.7539L36.8116 43.6164Z" fill={hex} />
        </svg>
    )
}

export const IconLogout = ({ hex, size, ...rest }) => {
    return (
        <div {...rest} style={{ color: hex || color.blue }}>
            <Icon size={size || 20} icon={logOut} />
        </div>
    )
}

export const IconDown = ({ hex, size, ...rest }) => {
    return (
        <div {...rest} style={{ color: hex || color.blue }}>
            <Icon size={size || 20} icon={chevronDown} />
        </div>
    )
}

export const IconChangeUser = ({ hex, size, ...rest }) => {
    return (
        <div {...rest} style={{ color: hex || color.blue }}>
            <Icon size={size || 20} icon={spinner9} />
        </div>
    )
}

export const IconSuccess = ({ hex, size, ...rest }) => {
    return (
        <div {...rest} style={{ color: hex || color.blue }}>
            <Icon size={size || 20} icon={pocket} />
        </div>
    )
}

export const IconError = ({ hex, size, ...rest }) => {
    return (
        <div {...rest} style={{ color: hex || color.blue }}>
            <Icon size={size || 20} icon={alertOctagon} />
        </div>
    )
}

export const IconCross = ({ hex, size, ...rest }) => {
    return (
        <div {...rest} style={{ color: hex || color.blue }}>
            <Icon size={size || 20} icon={x} />
        </div>
    )
}

export const IconFilter = ({ hex, size, ...rest }) => {
    return (
        <div {...rest} style={{ color: hex || color.blue }}>
            <Icon size={size || 20} icon={filter} />
        </div>
    )
}

export const IconTrash = ({ hex, size, ...rest }) => {
    return (
        <div {...rest} style={{ color: hex || color.blue }}>
            <Icon size={size || 20} icon={trash2} />
        </div>
    )
}

export const IconEdit = ({ hex, size, ...rest }) => {
    return (
        <div {...rest} style={{ color: hex || color.blue }}>
            <Icon size={size || 20} icon={edit2} />
        </div>
    )
}

export const IconPlus = ({ hex, size, ...rest }) => {
    return (
        <div {...rest} style={{ color: hex || color.blue }}>
            <Icon size={size || 20} icon={plus} />
        </div>
    )
}

export const IconCheck = ({ hex, size, ...rest }) => {
    return (
        <div {...rest} style={{ color: hex || color.blue }}>
            <Icon size={size || 20} icon={check} />
        </div>
    )
}

export const IconSearch = ({ hex, size, ...rest }) => {
    return (
        <div {...rest} style={{ color: hex || color.blue }}>
            <Icon size={size || 20} icon={search} />
        </div>
    )
}

export const IconEuro = ({ hex, size, ...rest }) => {
    return (
        <div {...rest} style={{ color: hex || color.blue }}>
            <Icon size={size || 20} icon={euro} />
        </div>
    )
}

export const IconList = ({ hex, size, ...rest }) => {
    return (
        <div {...rest} style={{ color: hex || color.blue }}>
            <Icon size={size || 20} icon={listCircle} />
        </div>
    )
}

export const IconAtom = ({ hex, size, ...rest }) => {
    return (
        <div {...rest} style={{ color: hex || color.blue }}>
            <Icon size={size || 20} icon={atom} />
        </div>
    )
}

export const IconImport = ({ hex, size, ...rest }) => {
    return (
        <div {...rest} style={{ color: hex || color.blue }}>
            <Icon size={size || 20} icon={arrowDownBig} />
        </div>
    )
}

export const IconUser = ({ hex, size, ...rest }) => {
    return (
        <div {...rest} style={{ color: hex || color.blue }}>
            <Icon size={size || 20} icon={user} />
        </div>
    )
}

export const IconMenu = ({ hex, size, ...rest }) => {
    return (
        <div {...rest} style={{ color: hex || color.blue }}>
            <Icon size={size || 20} icon={menu} />
        </div>
    )
}

export const IconBox = ({ hex, size, ...rest }) => {
    return (
        <div {...rest} style={{ color: hex || color.blue }}>
<Icon size={size || 20} icon={box} />
        </div>
)
}

export const IconManual = ({ hex, size, ...rest }) => {
    return (
        <div {...rest} style={{ color: hex || color.blue }}>
            <Icon size={size || 20} icon={manuals}/>
        </div>
    )
}