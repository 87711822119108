import React, { useState, useContext } from 'react';
import api from '../../api.json'
import axios from "axios";

import { EmployeesContext } from '../../contexts/Company.EmployeesContext'
import { Button } from '../../styled/Cta'
import { parseUrl } from '../../scripts/util'
import { Callout } from '../../styled/Callout'
import styled from "styled-components";
import InputFiles from "react-input-files";
import { color } from "../../styled/Global";
import mime from "mime-types";

const Dashboard = styled.div``;

const CustomRequests = styled.div``;

const CustomRequestsCard = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
        font-weight: 600;
        margin-bottom: 0;
    }
`;

const ImportButtons = styled.div`
    display: flex;
    justify-content: space-around;
`;

function EmployeeImport() {
    const employeesContext = useContext(EmployeesContext)
    const { updateList } = employeesContext
    const url = parseUrl(api.express.company.import_employee);
    const [formError, setFormError] = useState(false);
    const [result, setResult] = useState(false);

    const getErrorMessage = (errors) => {
        let errorMessage = "";

        for (let i = 0; i < 10 && i < errors.length; i++) {
            errorMessage += "Linea: " + errors[i].row + "\n";
            for (const [key, values] of Object.entries(errors[i].message)) {
                for (let i = 0; i < values.length; i++) {
                    errorMessage += values[i];
                }
            }
            errorMessage += "\n";
        }

        return errorMessage;
    };

    const checkFileExtension = (file) => {
        const validFileExtensions = [".xlsx", ".csv"];
        if (file != null) {
            return (new RegExp('(' + validFileExtensions.join('|').replace(/\./g, '\\.') + ')$')).test(file.name);
        }
        else {
            return false;
        }
    };

    //custom form validation
    const validate = (inputFile) => {
        let errors = [];
        let valid = true;

        if (inputFile != null) {
            if (checkFileExtension(inputFile) === false) {
                errors.push("File non valido");
                errors.push("estensione non riconosciuta");
                valid = false;
            }
        }
        else {
            errors.push("Nessun file caricato");
            valid = false
        }

        if (valid === false) {
            let errString = "Sono stati riscontrati degli errori: ";
            errString += errors.toString();
            setFormError(errString);
        }
        return valid;
    };

    const uploadFile = (files) => {
        let file = files.item(0);

        if (checkFileExtension(file) === false) {
            setFormError("Sono stati riscontrati degli errori: File non valido, estensione non riconosciuta");
        }
        else {
            setFormError(false);
            importFile(file);
        }
    };

    // import a file
    const importFile = (file) => {
        const formData = new FormData();
        formData.append('file', file);

        setFormError("");
        setResult("");

        if (validate(file) === true) {
            axios.post(url, formData, { withCredentials: true })
                .then(res => {
                    const imported = res.data.results.imported;
                    const failures = res.data.results.failures;
                    const errors = res.data.results.errors;

                    let errorString = "";
                    if (failures.count > 0) {
                        errorString += "Validazioni fallite: " + failures.count + "\n" + getErrorMessage(failures.messages) + "\n";
                    }
                    if (errors.count > 0) {
                        errorString += "Errori riscontrati: " + errors.count + "\n" + getErrorMessage(errors.messages) + "\n";
                    }

                    setFormError(errorString);
                    if (imported > 0)
                        setResult("Dipendenti importati: " + imported);
                })
                .catch(error => {
                    console.log(error);
                    setFormError("Sono stati riscontrati degli errori nell'eseguire l'operazione.");
                })
                .finally(() => {
                    // Update employee list after import file
                    updateList()
                })

        }
        else {
            setResult("");
        }
    };

    const onDownload = (e) => {
        const url = parseUrl(api.express.company.download_sample);

        setFormError("");
        setResult("");

        axios.post(url, {}, { withCredentials: true, responseType: 'arraybuffer' })
            .then(res => {
                const type = res.headers['content-type'];
                const blob = new Blob([res.data], { type: type });
                const fileExtension = mime.extension(type);
                const URL = window.URL || window.webkitURL;
                const downloadUrl = URL.createObjectURL(blob);
                let downloadLink = document.createElement('a');

                downloadLink.target = '_blank';
                downloadLink.download = 'sample.' + fileExtension; //need to specify extension type
                // set object URL as the anchor's href
                downloadLink.href = downloadUrl;
                // append the anchor to document body
                document.body.appendChild(downloadLink);
                // fire a click event on the anchor
                downloadLink.click();
                // cleanup: remove element and revoke object URL
                document.body.removeChild(downloadLink);
                URL.revokeObjectURL(downloadUrl);
            })
            .catch(error => {
                switch (error.response.status) {
                    case 404:
                        setFormError("File non trovato");
                        break;
                    default:
                        setFormError("Non è stato possibile eseguire l'operazione a causa di un errore del server");
                        break;
                }
                console.log(error);
            })
    };

    return (
        <div>
            {formError
                ? <Callout status="error" >{formError}</Callout>
                : ''
            }
            {result
                ? <Callout status="success" >{result}</Callout>
                : ''
            }
            <Dashboard>
                {/* <Title>File Excel con dipendenti da importare</Title> */}
                <ImportButtons>
                    <Button onClick={(e) => onDownload(e)} >Download Template</Button>
                    <CustomRequests>
                        <CustomRequestsCard>
                            <InputFiles accept=".xlsx, .xls, .csv" multiple={false} onChange={files => uploadFile(files)}>
                                <Button color={color.warning}>Carica File</Button><br />
                                <span id="filename"> </span>
                            </InputFiles>
                        </CustomRequestsCard>
                    </CustomRequests>
                </ImportButtons>
            </Dashboard>
        </div>
    )
}

export default EmployeeImport
