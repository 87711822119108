import React, { useState, useEffect, createContext } from 'react'
import axios from 'axios'
import api from '../api.json'
import { parseUrl } from '../scripts/util'

export const ExpenseReportContext = createContext()

export const ExpenseReportProvider = ({ children }) => {
    const [loading, setLoading] = useState(true)
    const [expenseReports, setExpenseReports] = useState()
    const [pagin, setPagin] = useState({})
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        // build url and update expense report list
        updateList()
    }, [currentPage])

    // get or update expense report list
    const fetchData = url => {
        axios.get(url, { withCredentials: true })
            .then(res => {
                // update expense report list
                setExpenseReports(res.data.data)
                // update paginator data
                setPagin(res.data.meta)
                // show list
                setLoading(false)
            })
    }
    
    // get expense report list
    const updateList = () => {
        const url = parseUrl(api.express.shared.expense_reports, [currentPage, '', '', '']);
        fetchData(url)
    }

    // update expense report list
    const filterList = (currentCompany, currentEmployee, currentStatusCode) => {
        const url = parseUrl(api.express.shared.expense_reports, [1, currentCompany, currentEmployee, currentStatusCode]);
        fetchData(url)
    }


    return (
        <ExpenseReportContext.Provider value={{
            loading,
            expenseReports,
            pagin,
            updateList,
            filterList,
            setCurrentPage
        }}>
            {children}
        </ExpenseReportContext.Provider>
    )
}
