import React, { useState, useEffect, useContext } from 'react'
import Select from 'react-select'
import axios from 'axios'
import api from '../../api.json'
import { parseUrl } from '../../scripts/util'
import { Paginator, PageInfo } from '../../styled/Pagination'
import { ExpenseReportCardOrganization } from '../../components/cards/ExpenseReportCard'
import { IconList } from "../../styled/Icons"
import { Title } from "../../components/PageTitle"
import { FormGrid, Field } from '../../styled/Forms'
import { Button } from '../../styled/Cta'
import { IconFilter } from '../../styled/Icons'
import styled from "styled-components"
import { color, mq } from "../../styled/Global"
import { Callout as BaseCallout } from "../../styled/Callout"
import mime from 'mime-types'
import { ExpenseReportProvider, ExpenseReportContext } from '../../contexts/Organization.ExpenseReportContext'

const TableHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 120px 120px 120px;
    padding: 10px 15px;
    text-transform: uppercase;
    font-size: 14px;
    align-items: center;

    @media ${mq.mediumDown} {
        display: none;
    }

    > div:nth-child(3),
    > div:nth-child(4),
    > div:nth-child(5) {
        justify-self: center;
    }
`

const Table = styled.div`
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
`

const Callout = styled(BaseCallout)`
    margin-bottom: 30px;
`

const FormActions = styled.div`
    grid-column: 3/4;
    justify-self: end;
`

const CustomField = styled(Field)`
    input[type="text"]{
        padding: 10px 15px;
        font-size: 14px;
        letter-spacing: 1px;
        display: block;
        width: 100%;
        border-radius: 25px;
        border: 1px solid ${color.gray};
    }
`

const ExpenseReportList = ({ reports, handleDownload, handleAccept, handleReject }) => {
    return (
        <>
            <TableHeader>
                <div>Dipendente</div>
                <div>Azienda</div>
                <div>Valore</div>
                <div>Stato</div>
                <div>Azioni</div>
            </TableHeader>
            <Table>
                {reports.map((el) =>
                    <ExpenseReportCardOrganization key={el.report_id}
                        id={el.report_id}
                        employee={el.employee}
                        company={el.company}
                        org={el.org_id}
                        created={el.created_at}
                        updated={el.updated_at}
                        description={el.description}
                        price={Math.round(el.value) !== 0 ? el.value : el.value}
                        file={el.file}
                        status={el.status_code}
                        rejection_reason={el.rejection_reason}
                        welfare_quote={el.welfare_quote_id}
                        handleDownload={handleDownload}
                        handleAccept={handleAccept}
                        handleReject={handleReject}
                    />
                )}
            </Table>
        </>
    )
};

const ExpenseReportContent = () => {
    const expenseReportContext = useContext(ExpenseReportContext)
    const { expenseReports, pagin, loading, currentStatusCode, setCurrentPage, 
        setCurrentCompany, setCurrentEmployee, setCurrentStatusCode, updateList, filterList } = expenseReportContext
    const [errorMsg, setErrorMsg] = useState(false)
    const [successMsg, setSuccessMsg] = useState(false)
    

    const stateOptions = [
        { value: 'pending', label: 'In attesa' },
        { value: 'accepted', label: 'Accettato' },
        { value: 'rejected', label: 'Rifiutato' },
    ];

    // Custom style for select component
    const customSelectStyles = {
        control: styles => ({ ...styles, borderRadius: 25, fontSize: 14 }),
    };

    const resetMsg = () => {
        setErrorMsg(false);
        setSuccessMsg(false);
    };

    useEffect(() => {
        updateList()
    }, []);

    const handleDownload = (id) => {
        const url = parseUrl(api.express.shared.expense_reports_download);
        resetMsg();

        axios.post(url, { id: id }, { withCredentials: true, responseType: 'arraybuffer' })
            .then(res => {
                const type = res.headers['content-type'];
                const blob = new Blob([res.data], { type: type });
                const fileExtension = mime.extension(type);
                const URL = window.URL || window.webkitURL;
                const downloadUrl = URL.createObjectURL(blob);
                let downloadLink = document.createElement('a');

                downloadLink.target = '_blank';
                downloadLink.download = 'expense-report.' + fileExtension; //need to specify extension type
                // set object URL as the anchor's href
                downloadLink.href = downloadUrl;
                // append the anchor to document body
                document.body.appendChild(downloadLink);
                // fire a click event on the anchor
                downloadLink.click();
                // cleanup: remove element and revoke object URL
                document.body.removeChild(downloadLink);
                URL.revokeObjectURL(downloadUrl);
            })
            .catch(error => {
                switch (error.response.status) {
                    case 404:
                        setErrorMsg("Report non trovato");
                        break;
                    default:
                        setErrorMsg("Errore di comunicazione con il server! Contattare il supporto.");
                        break;
                }
                console.log(error);
            })
    };

    const handleAccept = (id, setAcceptModalState) => {
        const url = parseUrl(api.express.organization.expense_report_accept);
        resetMsg();
        axios.post(url, { reportId: id }, { withCredentials: true })
            .then(res => {
                updateList();
                setSuccessMsg("Report accettato con successo");
            })
            .catch(error => {
                switch (error.response.status) {
                    case 400:
                        setErrorMsg("Non è stato possibile accettare il report");
                        break;
                    default:
                        setErrorMsg("Errore di comunicazione con il server! Contattare il supporto.");
                        break;
                }
                console.log(error);
            });
        setAcceptModalState(false);
    };

    const handleReject = (id, reason, setRejectModalState) => {
        const url = parseUrl(api.express.organization.expense_report_reject);
        resetMsg();
        axios.post(url, { reportId: id, rejectionReason: reason }, { withCredentials: true })
            .then(res => {
                updateList();
                setSuccessMsg("Report rifiutato con successo")
            })
            .catch(error => {
                switch (error.response.status) {
                    case 400:
                        setErrorMsg("Non è stato possibile rifiutare il report")
                        break;
                    default:
                        setErrorMsg("Errore di comunicazione con il server! Contattare il supporto.")
                        break;
                }
                console.log(error);
            });
        setRejectModalState(false);
    };

    // filters expense report
    const handleFilter = e => {
        e.preventDefault()
        updateList()
    }

    return (
        <>
            {!loading
                ? <>
                    <Title icon={IconList} title="Le tue spese" />
                    <form onSubmit={e => handleFilter(e)}>
                        <FormGrid tcolumns={`repeat(3, 1fr)`} onSubmit={() => console.log('submit')}>
                            <CustomField>
                                <label>Ricerca per Ditta</label>
                                <input type="text" onChange={e => setCurrentCompany(e.target.value)} />
                            </CustomField>
                            <CustomField>
                                <label>Ricerca per Dipendente</label>
                                <input type="text" onChange={e => setCurrentEmployee(e.target.value)} />
                            </CustomField>
                            <Field>
                                <label>Ricerca per Stato</label>
                                <Select
                                    styles={customSelectStyles}
                                    value={currentStatusCode}
                                    onChange={setCurrentStatusCode}
                                    options={stateOptions}
                                    isMulti
                                />
                            </Field>
                            <FormActions>
                                <Button icon={IconFilter}>Filtra</Button>
                            </FormActions>
                        </FormGrid>
                    </form>
                    <PageInfo tot={pagin.total} curr={pagin.current_page} last={pagin.last_page} />
                    {successMsg
                        ? <Callout status="success" title={successMsg} />
                        : ''
                    }
                    {errorMsg
                        ? <Callout status="error" title={errorMsg} />
                        : ''
                    }
                    <ExpenseReportList reports={expenseReports} handleDownload={handleDownload} handleAccept={handleAccept} handleReject={handleReject} />
                    <Paginator pageCount={pagin.last_page} onPageChange={page => setCurrentPage(page + 1)} />
                </>
                : ''
            }
        </>
    )
};


const ExpenseReport = () => {
    return (
        <ExpenseReportProvider>
            <ExpenseReportContent />
        </ExpenseReportProvider>
    )
}

export default ExpenseReport
