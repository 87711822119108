import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import api from '../../api.json'
import { parseUrl } from '../../scripts/util'
import { Paginator, PageInfo } from '../../styled/Pagination'
import { ExpenseReportCardEmployee } from '../../components/cards/ExpenseReportCard'
import { Modal } from "../../styled/Modal";
import ExpenseReportImport from "./ExpenseReportImport";
import { IconList, IconPlus } from "../../styled/Icons";
import { Button } from "../../styled/Cta";
import { Title } from "../../components/PageTitle";
import styled from "styled-components";
import { mq } from "../../styled/Global";
import { Callout as BaseCallout } from "../../styled/Callout";
import mime from 'mime-types';
import { ExpenseReportProvider, ExpenseReportContext } from '../../contexts/Employee.ExpenseReportContext'
import { EmployeeContext } from '../../contexts/EmployeeContext'

const TableHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr 120px 200px 120px;
    padding: 10px 15px;
    text-transform: uppercase;
    font-size: 14px;

    @media ${mq.mediumDown} {
        display: none;
    }

    > div:nth-child(2),
    > div:nth-child(3),
    > div:nth-child(4) {
        justify-self: center;
    }
`;

const Table = styled.div`
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
`;

const Callout = styled(BaseCallout)`
    margin-bottom: 30px;
`;

const ExpenseReportList = ({ reports, handleDownload, handleDelete, handleDownloadInstructions }) => {
    return (
        <>
            <TableHeader>
                <div>Creato</div>
                <div>Valore</div>
                <div>Stato</div>
                <div>Azioni</div>
            </TableHeader>
            <Table>
                {reports.map((el) =>
                    <ExpenseReportCardEmployee key={el.report_id}
                        id={el.report_id}
                        employee={el.employee_id}
                        company={el.company_id}
                        org={el.org_id}
                        description={el.description}
                        created={el.created_at}
                        updated={el.updated_at}
                        price={Math.round(el.value) !== 0 ? el.value : el.value}
                        file={el.file}
                        status={el.status_code}
                        welfare_quote={el.welfare_quote_id}
                        handleDownload={handleDownload}
                        handleDelete={handleDelete}
                        handleDownloadInstructions={handleDownloadInstructions}
                    />
                )}
            </Table>
        </>
    )
};

const ExpenseReportContent = () => {
    const expenseReportContext = useContext(ExpenseReportContext)
    const { expenseReports, pagin, loading, setCurrentPage, updateList } = expenseReportContext
    const employeeContext = useContext(EmployeeContext)
    const { updateQuotes } = employeeContext
    const [addModalState, setAddModalState] = useState(false)
    const [errorMsg, setErrorMsg] = useState(false)
    const [successMsg, setSuccessMsg] = useState(false)

    const resetMsg = () => {
        setErrorMsg(false);
        setSuccessMsg(false);
    }

    useEffect(() => {
        updateList()
    }, [])

    const handleImport = (setSuccess, setError, formData, value, quote, description) => {
        const url = parseUrl(api.express.employee.expense_report_create, [value, quote, description.replace('&', 'E')]);
        resetMsg();

        axios.post(url, formData, { withCredentials: true })
            .then(res => {
                setSuccess("Report creato con successo");
                updateList();
            })
            .catch(error => {
                switch (error.response.status) {
                    case 500:
                        setError('Errore di comunicazione con il server! Contattare il supporto.');
                        break;
                    default:
                        setError('Errore di comunicazione con il server! Contattare il supporto.');
                        break;
                }
                console.log(error);
            })
            .finally(() => {
                // Always update the employee context (quotes and service list data)
                updateQuotes()
            })
    };

    const handleDownload = (id) => {
        const url = parseUrl(api.express.shared.expense_reports_download);
        resetMsg();

        axios.post(url, { id: id }, { withCredentials: true, responseType: 'arraybuffer' })
            .then(res => {
                const type = res.headers['content-type'];
                const blob = new Blob([res.data], { type: type });
                const fileExtension = mime.extension(type);
                const URL = window.URL || window.webkitURL;
                const downloadUrl = URL.createObjectURL(blob);
                let downloadLink = document.createElement('a');

                downloadLink.target = '_blank';
                downloadLink.download = 'expense-report.' + fileExtension; //need to specify extension type
                // set object URL as the anchor's href
                downloadLink.href = downloadUrl;
                // append the anchor to document body
                document.body.appendChild(downloadLink);
                // fire a click event on the anchor
                downloadLink.click();
                // cleanup: remove element and revoke object URL
                document.body.removeChild(downloadLink);
                URL.revokeObjectURL(downloadUrl);
            })
            .catch(error => {
                switch (error.response.status) {
                    case 404:
                        setErrorMsg("Report non trovato");
                        break;
                    default:
                        setErrorMsg('Errore di comunicazione con il server! Contattare il supporto.');
                        break;
                }
                console.log(error);
            })
    };

    const handleDownloadInstructions = (setError) => {
        const url = parseUrl(api.express.shared.expense_reports_guide);
        resetMsg();

        axios.post(url, {}, { withCredentials: true, responseType: 'arraybuffer' })
            .then(res => {
                const type = res.headers['content-type'];
                const blob = new Blob([res.data], { type: type });
                const fileExtension = mime.extension(type);
                const URL = window.URL || window.webkitURL;
                const downloadUrl = URL.createObjectURL(blob);
                let downloadLink = document.createElement('a');

                downloadLink.target = '_blank';
                downloadLink.download = 'Guida_domande_rimborso_spese.' + fileExtension; //need to specify extension type
                // set object URL as the anchor's href
                downloadLink.href = downloadUrl;
                // append the anchor to document body
                document.body.appendChild(downloadLink);
                // fire a click event on the anchor
                downloadLink.click();
                // cleanup: remove element and revoke object URL
                document.body.removeChild(downloadLink);
                URL.revokeObjectURL(downloadUrl);
            })
            .catch(error => {
                switch (error.response.status) {
                    case 404:
                        setError("Report non trovato");
                        break;
                    default:
                        setError('Errore di comunicazione con il server! Contattare il supporto.');
                        break;
                }
                console.log(error);
            })
    };

    const handleDelete = (id, setDeleteModalState) => {
        const url = parseUrl(api.express.employee.expense_report_delete);
        resetMsg();
        axios.post(url, { reportId: id }, { withCredentials: true })
            .then(res => {
                updateList();
                setSuccessMsg("Report cancellato con successo");
            })
            .catch(error => {
                switch (error.response.status) {
                    case 400:
                        setErrorMsg("Il report è già stato cancellato");
                        break;
                    default:
                        setErrorMsg('Errore di comunicazione con il server! Contattare il supporto.');
                        break;
                }
                console.log(error);
            })
            .finally(() => {
                // Always update the employee context (quotes and service list data)
                updateQuotes()
            });
        setDeleteModalState(false);
    };

    return (
        <>
            {!loading
                ? <>
                    <Button icon={IconPlus} mb="30px" onClick={() => setAddModalState(!addModalState)}>Nuovo</Button>
                    <Modal
                        title="Nuovo servizio a rimborso"
                        isOpen={addModalState}
                        close={() => setAddModalState(!addModalState)}
                    >
                        <ExpenseReportImport handleImport={handleImport} handleDownloadInstructions={handleDownloadInstructions} />
                    </Modal>
                    <Title icon={IconList} title="Le tue spese" />
                    <PageInfo tot={pagin.total} curr={pagin.current_page} last={pagin.last_page} />
                    {successMsg
                        ? <Callout status="success" title={successMsg} />
                        : ''
                    }
                    {errorMsg
                        ? <Callout status="error" title={errorMsg} />
                        : ''
                    }
                    <ExpenseReportList reports={expenseReports} handleDownload={handleDownload} handleDelete={handleDelete} />
                    <Paginator pageCount={pagin.last_page} onPageChange={page => setCurrentPage(page + 1)} />
                </>
                : ''
            }
        </>
    )
};

const ExpenseReport = () => {
    return (
        <ExpenseReportProvider>
            <ExpenseReportContent />
        </ExpenseReportProvider>
    )
}

export default ExpenseReport
